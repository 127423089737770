import * as React from 'react';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone, OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {ReactBaseComponent} from '../../../react/base/react-base.component';
import {ArticleUsageStateModal} from '../../../react/article/state/article-usage-state-modal';
import {RightService} from '../../../common/right-service/right.service';

const containerElementName = 'reactArticleUsageStateModal';

@Component({
  selector: 'article-usage-state-modal',
  template: `<div #${containerElementName}></div>`,
})
export class ArticleUsageStateModalComponent extends ReactBaseComponent implements OnInit {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() oaNr: string;
  @Input() usageState: number;
  @Output() usageStateUpdated? = new EventEmitter<number>();
  hasEditPermissions: boolean;

  constructor(private ngZone: NgZone, private rightService: RightService) {
    super();
  }
  ngOnInit() {
    this.hasEditPermissions = this.rightService.has('article.change_state');
  }

  updated(usageState: number): void {
    this.ngZone.run(() => {
      this.usageStateUpdated.emit(usageState);
    });
  }

  protected renderComponent(): JSX.Element {
    const _ = React.version;
    return <ArticleUsageStateModal oaNr={this.oaNr}
                                   usageState={this.usageState}
                                   hasEditPermissions={this.hasEditPermissions}
                                   usageStateChanged={usageState => this.updated(usageState)}/>;
  }
}
