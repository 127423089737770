import * as React from 'react';
import {ArticleUsageStateStandaloneProps} from './article-usage-state.model';
import {useArticle} from '../article/article-hook';
import {ArticleUsageStateModal} from './article-usage-state-modal';
import {EvLoadingSpinnerElement} from '../../components/ev-loading-spinner-element';
import {useEffect} from 'react';

export function ArticleUsageStateStandaloneModal(props: ArticleUsageStateStandaloneProps): JSX.Element {
  const articleHook = useArticle();

  useEffect(() => {
    if (props.oaNr !== articleHook.state.article?.oa_nr) {
      articleHook.get(props.oaNr);
    }
  }, [props.oaNr]);

  if (!articleHook.state.isLoaded || !articleHook.state.article) {
    return <EvLoadingSpinnerElement />;
  }

  return <ArticleUsageStateModal oaNr={articleHook.state.article.oa_nr}
                                 usageState={articleHook.state.article.usage_state}
                                 hasEditPermissions={props.hasEditPermissions} />;
}
