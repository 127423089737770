import * as React from 'react';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  labeltype: string | 'danger' | 'warning' | 'info';
}

export function EvLabel(props: Props): JSX.Element {
  return <span {...props}
               className={`
    ${props.className ?? ''}
    label-${props.labeltype}
    pt-[2px] pr-[6px] pb-[2px] pl-[6px]
    rounded
    leading-4
    align-baseline
    text-center text-white
    whitespace-nowrap
    font-normal
    text-[90%]
    `}/>;
}
