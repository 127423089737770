<div class="filter-row">
  <div class="filter-left">
    <search-type typ="de" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>
    <search-type typ="cn" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('0')" [ngClass]="{active: q('0')}">
      Offen
    </button>
    <button class="btn btn-info btn-sm" type="button" (click)="badge('-100')" [ngClass]="{active: q('-100')}">
      Fällig
    </button>
    <button class="btn btn-success btn-sm" type="button" (click)="badge('3')" [ngClass]="{active: q('3')}">
      Bezahlt
    </button>
    <button class="btn btn-default btn-sm" type="button" (click)="badge('all')" [ngClass]="{active: q('all') === true}">
      Alle
    </button>

    <ev-file-button *ngIf="('opos.export'|HasPerm)" [url]="downloadUrl('/api/v1/workflow/opos/export/')" [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>
  </div>
  <div class="filter-right">
    <!-- Invoice Date Filter -->
    <date-range [start]="null" [end]="null" (update)="updateDateRange($event)" class="float-right"></date-range>

    <h5 class="align-special float-right">
      Rechnungsdatum:
    </h5>


    <!-- Global Opos Date -->
    <form class="form-inline float-right" [formGroup]="oposForm">
      <div class="form-group">

        <ev-datepicker class="form-control input-sm datepicker-width-100"
                    formControlName="global_opos_date"
                    [allDates]="true">
        </ev-datepicker>
      </div>
    </form>
    <h5 class="align-special float-right">Globales Buchungsdatum:</h5>

    <div class="clear-both">&nbsp;</div>
  </div>
</div>

<form #changeForm="ngForm" name="form">

  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'id', 'DESC')" [href]="sortUrl('id', 'ASC')">
          Rechnung
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_dunning_level', 'ASC')" [href]="sortUrl('invoice_dunning_level', 'ASC')">
          Mahnstufe
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'workflow_id', 'DESC')" [href]="sortUrl('workflow_id', 'DESC')">
          Vorgang
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'date', 'DESC')" [href]="sortUrl('date', 'DESC')">
          RG-Datum
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_id', 'DESC')" [href]="sortUrl('customer_id', 'DESC')">
          Kunden-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'DESC')" [href]="sortUrl('customer_name', 'DESC')">
          Kunde
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_field_service', 'DESC')" [href]="sortUrl('customer_field_service', 'DESC')">
          Vert.-gebiet
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_due_date', 'ASC')" [href]="sortUrl('invoice_due_date', 'ASC')">
          Fälligkeit
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_revenue', 'DESC')" [href]="sortUrl('invoice_revenue', 'DESC')">
          RG-Betrag
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_open', 'DESC')" [href]="sortUrl('invoice_open', 'DESC')">
          Offen €
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_expensed', 'DESC')" [href]="sortUrl('invoice_expensed', 'DESC')">
          Gebucht €
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_expensed_date', 'DESC')" [href]="sortUrl('invoice_expensed_date', 'DESC')">
          Buchungsdatum
        </a>
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>

    <tr class="filterrow">
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.invoice_id" name="invocie_id" [autofocus]>
      </th>
      <th>
        <select class="form-control input-sm" [(ngModel)]="data.invoice_dunning_level" name="invoice_dunning_level">
          <option [value]="''">- Mahnstufe -</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.workflow_id" name="workflow_id" autocomplete="off search-workflow-id">
      </th>
      <th>
        <ev-datepicker [(ngModel)]="data.date" name="date" class="form-control input-sm" [allDates]="true">
        </ev-datepicker>
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_id" name="customer_id">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_name" name="customer_name">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_field_service"
               name="customer_field_service">
      </th>
      <th>
        <ev-datepicker [(ngModel)]="data.invoice_due_date" name="invoice_due_date" class="form-control input-sm"
                       [allDates]="true">
        </ev-datepicker>
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.invoice_revenue" name="invoice_revenue">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.invoice_open" name="invoice_open">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.invoice_expensed" name="invoice_expensed">
      </th>
      <th>
        <ev-datepicker [(ngModel)]="data.invoice_expensed_date" name="invoice_expensed_date"
                       class="form-control input-sm"
                       [allDates]="true">
        </ev-datepicker>
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let object of list.objects;trackBy: trackByFn; let index = index" class="chrissies">
      <td class="width-200">
        <ev-file-button [objId]="object.id" type="invoice">
          <span [ngClass]="{deleted: object.invoice_storno_id}">{{ object.id }}</span>
          <span class="label" [ngClass]="'label-' + object.invoice_state.label">{{ object.invoice_state.name }}</span>
          <span class="label" ng-class="'label-' + object.sent.label" *ngIf="object.sent">{{ object.sent.name }}</span>
        </ev-file-button>
        <ev-file-button [objId]="object.invoice_storno_id" type="invoice_correction" *ngIf="object.invoice_storno_id">
          <span class="label label-warning">Storno</span>
        </ev-file-button>
      </td>
      <td>{{ object.invoice_dunning_level }}</td>
      <td>
        <a [href]="'#!/workflow/' + object.workflow_id">
          <img *ngIf="object.typ === 'cn'" src="/ui/assets/images/flags/cn.png">
          <img *ngIf="object.typ === 'de'" src="/ui/assets/images/flags/de.png">
          {{ object.workflow_id }}
          <span *ngIf="object.comments > 0" class="td-comments" title="Kommentar(e) vorhanden">
            <i class="fa fa-comments"></i>
          </span>
        </a>
      </td>
      <td>{{ object.invoice_date }}</td>
      <td>{{ object.customer_id }}</td>
      <td>{{ object.customer_name }}</td>
      <td>{{ object.customer_field_service }}</td>
      <td>{{ object.target_date ?? object.invoice_due_date }}</td>
      <td>{{ object.invoice_revenue }}</td>
      <td>{{ object.open }}</td>
      <td>{{ object.expensed }}</td>
      <td>{{ object.expensed_date }}</td>
      <td>
        <a class="btn btn-default btn-sm" uiSref="a.crm.customer.opos" [uiParams]="invoicesFilter(object)">
          Buchen
        </a>
      </td>
      <td>
        <button type="button" class="btn btn-sm btn-default" (click)="invoiceDunningLock(object, index)">
          <span *ngIf="!object.invoice_dunning_lock">Sperren</span>
          <span *ngIf="object.invoice_dunning_lock">Entsperren</span>
        </button>
        <div class="label label-danger" *ngIf="object.invoice_dunning_lock">Gesperrt</div>
      </td>
      <td>
        <button type="button" class="btn btn-sm btn-link" (click)="history(object)">
          <i class="fa fa-history"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
