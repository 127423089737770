import * as React from 'react';
import {EvLoadingSpinnerElement} from '../../components/ev-loading-spinner-element';
import {useArticleMeasurementAnalysisState} from './article-measurement-analysis-state-hook';
import {useEffect} from 'react';
import {ArticleMeasurementLister} from './article-measurement-analysis.model';
import {ArticleMeasurementAnalysis} from './article-measurement-analysis';

interface Props {
  oaNr: string;
  questionnaireId: number;
  articleValue?: string | number;
  list?: ArticleMeasurementLister;
  listUpdated: (list: ArticleMeasurementLister) => void;
}

export function ArticleMeasurementAnalysisWrapper(props: Props): JSX.Element {
  if (!props.list || props.list.questionnaire.data_type <= 0 || props.list.questionnaire.data_type >= 128) {
    return <></>;
  }

  const hook = useArticleMeasurementAnalysisState(props);

  useEffect(() => {
    if (props.articleValue !== hook.state.articleValue) {
      hook.reload();
    }
  }, [props.articleValue]);

  return <ArticleMeasurementAnalysis {...hook} />;
}
