import * as React from 'react';
import {UseArticleUsageStateModal} from './article-usage-state-hook';
import {ArticleUsageStateProps, UsageStateTypes} from './article-usage-state.model';
import {EvReactModal} from '../../modal/EvReactModal';
import {EvLabel} from '../../components/labels/ev-label';

export function ArticleUsageStateModal(props: ArticleUsageStateProps) {
  const hook = UseArticleUsageStateModal(props);
  if (!props.hasEditPermissions) {
    return <EvLabel labeltype={hook.state.usageState.className}
                    className="w-20"
                    title="Artikelstatus bearbeiten"
                    onClick={() => hook.setModalState('open')}>
      {hook.state.usageState.stateName}
    </EvLabel>;
  }

  return <>
    <EvLabel labeltype={hook.state.usageState.className}
             className="w-20 cursor-pointer"
             title="Artikelstatus bearbeiten"
             onClick={() => hook.setModalState('open')}>
      {hook.state.usageState.stateName}
    </EvLabel>
    <EvReactModal contentLabel="Artikelstatus bearbeiten"
                  showModal={hook.state.modalState === 'open'}
                  modalClosed={() => hook.setModalState('closed')}>
      <div className="grid grid-cols-3 grid-rows-1">
        {UsageStateTypes.list.map(t =>
          <div key={t.state}
               className="col-start-2">
            <button type="button"
                    className={`btn btn-sm btn-${t.className} w-32`}
                    onClick={() => hook.setUsageState(t.state)}>
              {t.stateName}
            </button>
          </div>
        )}
      </div>
    </EvReactModal>
  </>;
}
