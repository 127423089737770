import * as React from 'react';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';
import {ArticleMeasurementLister} from '../../../../react/article/analysis/article-measurement-analysis.model';
import {ArticleMeasurementAnalysisSum} from '../../../../react/article/analysis/article-measurement-analysis-sum';

const containerElementName = 'reactArticleAnalysisSum';

@Component({
  selector: 'article-analysis-sum',
  template: `<div #${containerElementName}></div>`,
})
export class ArticleAnalysisSumComponent extends ReactBaseComponent implements OnInit, OnChanges {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() oaNr: string;
  @Input() label: string;
  @Input() questionnaireIds: number[];
  @Input() measurementLists?: ArticleMeasurementLister[];
  @Output() measurementListsUpdated? = new EventEmitter<ArticleMeasurementLister>();

  constructor(private ngZone: NgZone) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  listUpdated(list: ArticleMeasurementLister): void {
    this.ngZone.run(() => {
      this.measurementListsUpdated.emit(list);
    });
  }

  protected renderComponent(): JSX.Element {
    const _ = React.version;
    return <ArticleMeasurementAnalysisSum
      oaNr={this.oaNr}
      label={this.label}
      questionnaireIds={this.questionnaireIds}
      measurementLists={this.measurementLists}
      listUpdated={list => this.listUpdated(list)}/>;
  }
}
