import * as React from 'react';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';
import {
  ArticleMeasurementAnalysisWrapper,
} from '../../../../react/article/analysis/article-measurement-analysis-wrapper';
import {ArticleMeasurementLister} from '../../../../react/article/analysis/article-measurement-analysis.model';

const containerElementName = 'reactArticleAnalysis';

@Component({
  selector: 'article-analysis',
  template: `<div #${containerElementName}></div>`,
})
export class ArticleAnalysisComponent extends ReactBaseComponent implements OnInit, OnChanges {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() oaNr: string;
  @Input() questionnaireId: number;
  @Input() articleValue?: string | number;
  @Input() measurementLists?: ArticleMeasurementLister[];
  @Output() measurementListsUpdated? = new EventEmitter<ArticleMeasurementLister>();

  measurementList?: ArticleMeasurementLister;

  constructor(private ngZone: NgZone) {
    super();
  }

  ngOnInit() {
    this.measurementList = this.measurementLists?.find(m => m.questionnaire.id === this.questionnaireId) ?? null;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.measurementList = this.measurementLists?.find(m => m.questionnaire.id === this.questionnaireId) ?? null;
    super.ngOnChanges(changes);
  }

  listUpdated(list: ArticleMeasurementLister): void {
    this.ngZone.run(() => {
      this.measurementListsUpdated.emit(list);
    });
  }

  protected renderComponent(): JSX.Element {
    const _ = React.version;
    return <ArticleMeasurementAnalysisWrapper
      oaNr={this.oaNr}
      questionnaireId={this.questionnaireId}
      articleValue={this.articleValue}
      list={this.measurementList}
      listUpdated={list => this.listUpdated(list)}/>;
  }
}
