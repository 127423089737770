<div class="filter-row">
  <div class="filter-left">
    <search-type typ="de" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>
    <search-type typ="cn" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>

    <button class="btn btn-info btn-sm" type="button" (click)="badge('0')" [ngClass]="{active: q('0')}">
      Beauftragt <span class="badge ng-binding"></span>
    </button>
    <button class="btn btn-primary btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
      Bestätigt <span class="badge ng-binding"></span>
    </button>
    <button class="btn btn-primary btn-sm" type="button" (click)="badge('6')" [ngClass]="{active: q('6')}">
      DE Bestätigt ohne BA <span class="badge ng-binding"></span>
    </button>
    <button class="btn btn-purple btn-sm" type="button" (click)="badge('2')" [ngClass]="{active: q('2')}">
      In Produktion <span class="badge ng-binding"></span>
    </button>
    <button class="btn btn-rose btn-sm" type="button" (click)="badge('3')" [ngClass]="{active: q('3')}">
      Produziert <span class="badge ng-binding"></span>
    </button>
    <button class="btn btn-warning btn-sm" type="button" (click)="badge('4')" [ngClass]="{active: q('4')}">
      Geliefert <span class="badge ng-binding"></span>
    </button>
    <button class="btn btn-success btn-sm" type="button" (click)="badge('5')" [ngClass]="{active: q('5')}">
      Abgerechnet <span class="badge ng-binding"></span>
    </button>

    <button class="btn btn-danger btn-sm" type="button" (click)="badge('kanban')" [ngClass]="{active: q('kanban')}">
      Kanban <span class="badge"></span>
    </button>

    <button class="btn btn-default btn-sm active" type="button" (click)="badge('all')"
            [ngClass]="{active: q('all') === true}">
      Alle <span class="badge ng-binding"></span>
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('false', 'has_delivery_date', true)"
            [ngClass]="{active: q('false', 'has_delivery_date')}">
      Ohne Liefertermin <span class="badge"></span>
    </button>

    <ev-file-button *ngIf="('fetch.export'|HasPerm)" [url]="downloadUrl('/workflow/v1/fetch/export/')" [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>

    <ev-file-button *ngIf="('fetch.export'|HasPerm)" [url]="downloadUrl('/workflow/v1/fetch/export/?short=true', true)" [link]="false">
      <i class="fa fa-download"></i> Kurz
    </ev-file-button>
  </div>
  <div class="filter-right">

    <date-range [start]="null" [end]="null" (update)="updateDateRange($event)" class="float-right"></date-range>

    <h3 class="align-special float-right">
      Lieferdatum:
    </h3>

    <div class="clear-both">&nbsp;</div>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
      <tr>
        <th>
          <a (click)="sort($event, 'task_list_id', 'ASC')" [href]="sortUrl('task_list_id', 'ASC')">
            Vorgang
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'task_workflow_fetch_status', 'ASC')"
             [href]="sortUrl('task_workflow_fetch_status', 'ASC')">
            Status
          </a>
        </th>
        <th>
          Abruf
        </th>
        <th>
          <a (click)="sort($event, 'task_workflow_fetch_position_quanity', 'DESC')"
             [href]="sortUrl('task_workflow_fetch_position_quanity', 'DESC')">
            Stückzahl
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'task_workflow_fetch_delivery', 'ASC')"
             [href]="sortUrl('task_workflow_fetch_delivery', 'ASC')">
            Lieferdatum
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'task_list_sds_nr', 'ASC')" [href]="sortUrl('task_list_sds_nr', 'ASC')">
            SDS-Nr.
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'task_list_oa_nr', 'ASC')" [href]="sortUrl('task_list_oa_nr', 'ASC')">
            0A-Nr.
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'task_list_kd_art_nr', 'ASC')" [href]="sortUrl('task_list_kd_art_nr', 'ASC')">
            Kd-Art-Nr.
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'task_list_customer_name', 'ASC')"
             [href]="sortUrl('task_list_customer_name', 'ASC')">
            Kunde
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'task_list_customer_city', 'ASC')"
             [href]="sortUrl('task_list_customer_city', 'ASC')">
            Lieferadresse Stadt
          </a>
        </th>
        <th>
          <a (click)="sort($event, 'task_list_customer_field_service', 'ASC')"
             [href]="sortUrl('task_list_customer_field_service', 'ASC')">
            Vertriebsgebiet
          </a>
        </th>
        <th>
          Lagerbestand
        </th>
      </tr>
      <tr class="filterrow">
        <th>
          <input type="text" class="form-control input-sm" [(ngModel)]="data.sid" name="sid" [autofocus] autocomplete="off search-fetch-id">
        </th>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>
          <input type="text" class="form-control input-sm" [(ngModel)]="data.quantity" name="quantity">
        </th>
        <th>
          <ev-datepicker [(ngModel)]="data.delivery_date" name="delivery_date" class="form-control input-sm"
                      [allDates]="true">
          </ev-datepicker>
        </th>
        <th>
          <input type="text" class="form-control input-sm" [(ngModel)]="data.sds_nr" name="sds_nr">
        </th>
        <th>
          <input type="text" class="form-control input-sm" [(ngModel)]="data.oa_nr" name="sds_nr">
        </th>
        <th>
          <input type="text" class="form-control input-sm" [(ngModel)]="data.kd_art_nr" name="kd_art_nr">
        </th>
        <th>
          <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_name" name="customer_name">
        </th>
        <th>
          <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_city" name="customer_city">
        </th>
        <th>
          <input type="text" class="form-control input-sm" [(ngModel)]="data.customer_field_service"
                 name="customer_field_service">
        </th>
        <th>
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let object of list.objects; let index = index" [ngClass]="object.css">
        <td>
          <a uiSref="a.workflow.detail" [uiParams]="{id: object.task.id}">
            <img *ngIf="object.task.typ === 'cn'" src="/ui/assets/images/flags/cn.png">
            <img *ngIf="object.task.typ === 'de'" src="/ui/assets/images/flags/de.png">
            {{ object.task.id }}
            <span *ngIf="object.task.comments > 0" class="td-comments" title="Kommentar(e) vorhanden">
            <i class="fa fa-comments"></i>
          </span>
            <span class="label label-danger" *ngIf="object.task.kanban">Kanban</span>
          </a>
        </td>
        <td>
          <workflow-state type="fetch_status" [key]="object.fetch.status"></workflow-state>

          <ng-container *ngIf="object.task.express_service">
            <i class="fa fa-exclamation-triangle"></i> EIL
          </ng-container>
          <ng-container *ngIf="object.fix_date">
            <i class="fa fa-exclamation-triangle"></i> FIX
          </ng-container>
        </td>
        <td>{{ object.fetch.ordering }} von {{ object.task.fetches }}</td>
        <td>{{ object.position.quanity|money }}</td>
        <td>{{ object.fetch.delivery }}</td>
        <td>
          <strong>{{ object.task.sds_nr }}</strong>
          <workflow-article-state [articleState]="object.task.article_state_change"></workflow-article-state>
        </td>
        <td>{{ object.task.oa_nr }}</td>
        <td>
          {{ object.task.kd_art_nr }}
          <span *ngIf="object.task.kd_art_index" class="label label-default">
         {{ object.task.kd_art_index }}
        </span>
        </td>
        <td>
          <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.task.customer_id}" target="_blank">
            {{ object.task.customer_name }}
          </a>
        </td>
        <td>
          {{ object.task.customer_city }}
        </td>
        <td>
          {{ object.task.customer_field_service }}
        </td>
        <td>
          {{ object.inventory }}
        </td>

      </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
