<div class="modal-header">
  <div class="modal-title">{{ step.nr }} - {{ step.text }} abmelden</div>
</div>

<form [formGroup]="form" (ngSubmit)="submit()" class="form form-horizontal">
  <div class="modal-body">
    <div class="form-group" *ngIf="!step.use_good_pieces">
      <label for="id_wastrel" class="col-sm-3 control-label">Ausschuss (Menge)</label>

      <div class="col-sm-9">
        <input type="number" id="id_wastrel" class="form-control" formControlName="wastrel"
               [ngClass]="{red_alert: errors['wastrel']}">
      </div>
    </div>

    <div class="form-group" *ngIf="!!step.use_good_pieces">
      <label for="id_good_pieces" class="col-sm-3 control-label">
        <div class="label label-primary">Gutstück (Menge)</div>
      </label>

      <div class="col-sm-9">
        <input type="number" id="id_good_pieces" class="form-control" formControlName="good_pieces"
               [ngClass]="{red_alert: errors['good_pieces']}">
      </div>
    </div>

    <div class="form-group" *ngIf="this.lastGoodUtilize !== null">
      <label for="id_good_utilize" class="col-sm-3 control-label">Nutzenanzahl</label>

      <div class="col-sm-9">
        <input id="id_good_utilize"
               type="number"
               class="form-control"
               formControlName="good_utilize"
               [ngClass]="{red_alert: errors['good_utilize']}">
      </div>
    </div>

    <div class="form-group" *ngIf="(form.value.wastrel > 0) && (reasons.active)">
      <label for="id_wastrel_reason_id" class="col-sm-3 control-label">Ausschussgrund</label>

      <div class="col-sm-9">
        <select id="id_wastrel_reason_id"
                class="form-control"
                formControlName="wastrel_reason_id"
                [ngClass]="{red_alert: errors['wastrel_reason_id']}">
          <option [ngValue]="null">
            -
          </option>
          <option [ngValue]="reason.id" *ngFor="let reason of (reasons?.objects ?? [])">
            {{ reason.reason }}
          </option>
        </select>
      </div>
    </div>

    <ng-container formArrayName="questionnaire_forms" *ngFor="let questionnaireForm of questionnaireForms; trackBy: trackByIndexFn; let i = index">
      <div class="form-group" [formGroup]="questionnaireForm.form">
        <label [for]="'form_value_' + i" class="col-sm-3 control-label">{{questionnaireForm.questionnaire.name}}</label>
        <div class="col-sm-7" [ngSwitch]="questionnaireForm.questionnaire.data_type">
          <input [id]="'form_value_' + i" type="text" class="form-control" formControlName="questionnaire_value" *ngSwitchCase="0">
          <input [id]="'form_value_' + i" type="number" step="0.001" class="form-control align-right" formControlName="questionnaire_value" *ngSwitchCase="2">
          <input [id]="'form_value_' + i" type="number" step="0.001" min="0.001" class="form-control align-right" formControlName="questionnaire_value" *ngSwitchCase="3">
          <input [id]="'form_value_' + i" type="number" class="form-control align-right" formControlName="questionnaire_value" *ngSwitchCase="4">
          <input [id]="'form_value_' + i" type="number" min="0" class="form-control align-right" formControlName="questionnaire_value" *ngSwitchCase="5">
          <input [id]="'form_value_' + i" type="checkbox" class="form-control align-right" formControlName="questionnaire_value" *ngSwitchCase="128">
        </div>
        <div class="col-sm-2 control-label" *ngIf="!!questionnaireForm.questionnaire.unit">
          {{questionnaireForm.questionnaire.unit}}
        </div>
      </div>
    </ng-container>

    <div class="form-group">
      <label for="id_comment" class="col-sm-3 control-label">
        Kommentar <span *ngIf="commentIsRequired">(Pflicht)</span>
      </label>

      <div class="col-sm-9">
        <textarea id="id_comment" class="form-control" formControlName="comment"
                  [ngClass]="{red_alert: errors['comment']}">
        </textarea>
      </div>
    </div>

    <div class="form-group" *ngIf="!step.use_good_pieces && underDelivery && form.value.wastrel > 0">
      <div class="col-sm-8">
        <div class="control-label">
          Achtung! Lieferuntergrenze wird unterschritten!
        </div>
      </div>
      <div class="col-sm-4 text-right">
        <label for="force" class="control-label inline-block">
          Trotzdem abmelden
        </label>&nbsp;
        <input id="force"
               type="checkbox"
               class="form-control inline-block"
               style="width: 16px; height: 16px;"
               formControlName="force">
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errorMessages.length > 0">
      <div [ngSwitch]="message" *ngFor="let message of errorMessages">
        <ng-container *ngSwitchCase="'illegal_good_pieces'">
          Die Anzahl an Gutstücken darf nicht negativ oder leer sein
        </ng-container>
        <ng-container *ngSwitchCase="'illegal_good_utilize'">
          Die Anzahl an Nutzen darf nicht negativ oder leer sein
        </ng-container>
        <ng-container *ngSwitchCase="'illegal_wastrel'">
          Bitte Ausschuss angeben
        </ng-container>
        <ng-container *ngSwitchCase="'wastrel_and_good_pieces_null'">
          <ng-container *ngIf="!step.use_good_pieces">
            Bitte Ausschuss angeben
          </ng-container>
          <ng-container *ngIf="step.use_good_pieces">
            Die Anzahl an Gutstücken darf nicht negativ oder leer sein
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'wastrel_too_high'">
          Ausschuss zu hoch. Keine Abmeldung möglich
        </ng-container>
        <ng-container *ngSwitchCase="'wastrel_reason_required'">
          Bitte Ausschussgrund angeben
        </ng-container>
        <ng-container *ngSwitchCase="'comment_needed'">
          Bitte Kommentar eingeben
        </ng-container>
        <ng-container *ngSwitchCase="'under_delivery'">
          Lieferuntergrenze wird untertschritten
        </ng-container>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-success btn-sm pull-right" [disabled]="locked">
      Fertigungsschritt abmelden
    </button>
  </div>
</form>
