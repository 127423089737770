<div class="col-lg-12">

  <table class="table whitetable">
    <thead>
    <tr>
      <th style="width:120px;"><strong>Datum</strong></th>
      <th style="width:200px;"><strong>Benutzer</strong></th>
      <th style="width:250px;"><strong>Typ</strong></th>
      <th style="width:120px;"><strong>Art</strong></th>
      <th><strong>Kommentar</strong></th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let obj of history;trackBy:trackByFn"
        [ngClass]="{'alert-danger': (obj.event === 'lock' || obj.event === 'delete')}">
      <td>{{ obj.date }}</td>
      <td>{{ obj.username }}</td>
      <td>
        <ng-container [ngSwitch]="obj.event">
          <ng-container *ngSwitchCase="'create'">Artikel Erstellung</ng-container>
          <ng-container *ngSwitchCase="'delete'">Artikel Löschung</ng-container>
          <ng-container *ngSwitchCase="'sds_update'">Artikel SDS Änderung</ng-container>
          <ng-container *ngSwitchCase="'customer_update'">Artikel Kunden Änderung</ng-container>
          <ng-container *ngSwitchCase="'calculation_update'">Artikel Kalkulations Änderung</ng-container>
          <ng-container *ngSwitchCase="'lock'">Artikel Sperre</ng-container>
          <ng-container *ngSwitchCase="'unlock'">Artikel Entsperrung</ng-container>
          <ng-container *ngSwitchCase="'update'">Artikel Änderung</ng-container>
          <ng-container *ngSwitchCase="'recover'">Artikel Wiederherstellung</ng-container>
          <ng-container *ngSwitchCase="'change_state'">Artikel Änderungsstatus</ng-container>
          <ng-container *ngSwitchCase="'usage_state'">Artikel Nutzungsstatus</ng-container>
          <ng-container *ngSwitchDefault>Artikel Änderung</ng-container>
        </ng-container>
      </td>
      <td>{{ obj.reason }}</td>
      <td>
        <pre class="article-pre">{{ obj.reference }}</pre>
      </td>
    </tr>

    </tbody>
  </table>

</div>
