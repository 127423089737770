import {ArticleMultilayerPlan, ArticleMultilayerPlanModel} from './article-multilayer-plan.models';
import {Article} from '../../models/article.models';
import {ArticleSpecification} from '../../models/article-specification.model';
import {useEffect, useState} from 'react';
import {useFetchEffect} from '../../../../react/helper/react-async-fetch-effect';
import {ArticleMultilayerPlanAxiosService} from './article-multilayer-plan-axios.service';
import {ArticleMultilayerPlanForm} from './article-multilayer-plan-form';
import * as React from 'react';
import {ArticleMultilayerPlanHead} from './components/article-multilayer-plan-head';
import {ArticleMultilayerPlanHolderCompareHelper} from './article-multilayer-plan-holder-compare-helper';
import {
  ArticleMultilayerDifferenceWarningModal,
} from './modal/article-multilayer-difference-warning-modal';
import {EvReactModal} from '../../../../react/modal/EvReactModal';
import {ArticleMultilayerSaveBeforePdfModal} from './modal/article-multilayer-save-before-pdf-modal';
import {ArticleMultilayerSaveBeforeRedirectModal} from './modal/article-multilayer-save-before-redirect-modal';

interface Props {
  model: ArticleMultilayerPlan;
  externalModelChange: ArticleMultilayerPlanModel;
  article: Article;
  specification: { [key: string]: ArticleSpecification };
  isAdmin: boolean;
  changeModel: (model: ArticleMultilayerPlanModel) => void;
  modelSaved: (model: ArticleMultilayerPlan) => void;
  saveBtnPressed: number;
  saveBtnLocked: (saveBtnPressed: boolean) => void;
  copyModelBtnPressed: () => void;
  goToArticleAndInsert: () => void;
}

export function ArticleMultilayerPlanUpdate(props: Props) {
  const [modelState, modelStateSet] = useState<ArticleMultilayerPlanModel>(props.model.data);
  const [saveBtnPressed, saveBtnPressedSet] = useState<number>(props.saveBtnPressed);
  const [saveBtnLocked, saveBtnLockedSet] = useState<boolean>(false);
  const [modelChanged, modelChangedSet] = useState<boolean>(false);
  const [modalState, modalStateSet] = useState<number>(0);

  const changeModel: (m: ArticleMultilayerPlanModel) => void = (m) => {
    props.changeModel(m);
    modelStateSet(m);
    modelChangedSet(true);
  };

  const save: () => Promise<ArticleMultilayerPlan | null> = async () => {
    saveBtnLockedSet(true);
    props.saveBtnLocked(true);
    let plan: ArticleMultilayerPlan | null = null;
    try {
      plan = await ArticleMultilayerPlanAxiosService.update(props.article.oa_nr, modelState);
      props.changeModel(plan.data);
      props.modelSaved(plan);
      modelChangedSet(false);
      modelStateSet(plan.data);
    } finally {
      saveBtnLockedSet(false);
      props.saveBtnLocked(false);
    }
    saveBtnPressedSet(props.saveBtnPressed);
    return plan;
  };

  const checkArticleDifference: () => boolean = () => {
    return ArticleMultilayerPlanHolderCompareHelper.diff(props.article, modelState).differs;
  };

  const closeModal: () => void = () => {
    modalStateSet(0);
  };

  const saveConfirmed: (confirmed: boolean | null) => void = async (confirmed) => {
    closeModal();
    if (confirmed === true) {
      await save();
    }
  };

  /* Open html choice tree */
  const openHtml: (id: number) => void = async (id) => {
    try {
      const bewit = await ArticleMultilayerPlanAxiosService.bewitHtml(id);
      window.open(bewit.object, '_blank');
    } finally {
    }
  };

  /* Open PDF choice tree */
  const openPdf: (id: number) => void = async (id) => {
    try {
      const bewit = await ArticleMultilayerPlanAxiosService.bewitPdf(id);
      window.open(bewit.object, '_blank');
    } finally {
    }
  };

  // Save if confirmed
  const openPdfSave: (confirmed: boolean | null) => void = async (confirmed) => {
    closeModal();
    switch (confirmed) {
      case true:
        const updatedPlan = await save();
        if (!updatedPlan) {
          return;
        }
        await openPdf(updatedPlan.id);
        return;
      case null:
        await openPdf(props.model.id);
        return;
      case false:
        return;
    }
  };

  // Check if article conformity is given
  const openPdfCheckArticle: (confirmed: boolean) => void = async (confirmed) => {
    if (!confirmed) {
      closeModal();
    } else if (checkArticleDifference()) {
      modalStateSet(21);
    } else {
      await openPdfSave(true);
    }
  };

  // Check if changed, check if article conformity is given
  const openHtmlNoCheck: () => void = async () => {
    await openHtml(props.model.id);
  };

  // Check if changed, check if article conformity is given
  const openPdfCheckAll: () => void = async () => {
    if (saveBtnLocked) {
      return;
    }

    if (modelChanged) {
      modalStateSet(20);
    } else {
      await openPdf(props.model.id);
    }
  };

  /* CopyMl choice tree */
  const openArticleAndCopyMl: () => void = () => {
    props.goToArticleAndInsert();
  };

  // Save if confirmed
  const openArticleAndCopyMlSave: (confirmed: boolean | null) => void = async (confirmed) => {
    closeModal();
    switch (confirmed) {
      case true:
        const updatedPlan = await save();
        if (!!updatedPlan) {
          openArticleAndCopyMl();
        }
        return;
      case null:
        openArticleAndCopyMl();
        return;
      case false:
        return;
    }
  };

  // Check if article conformity is given
  const openArticleAndCopyMlCheckArticle: (confirmed: boolean) => void = async (confirmed) => {
    if (!confirmed) {
      closeModal();
    } else if (checkArticleDifference()) {
      modalStateSet(31);
    } else {
      await openArticleAndCopyMlSave(true);
    }
  };

  const openArticleAndCopyMlCheckAll: () => void = () => {
    if (saveBtnLocked) {
      return;
    }

    if (modelChanged) {
      modalStateSet(30);
    } else {
      openArticleAndCopyMl();
    }
  };

  /* Misc */
  const modalHeader: () => string = () => {
    switch (modalState) {
      case 20:
        return 'Der Aufbauplan muss vor dem erstellen der PDF gespeichert werden';
      case 30:
        return 'Der Aufbauplan muss vor der Übernahme gespeichert werden';
      default:
        return 'Die angegebenen Daten weichen von den Artikeldaten ab';
    }
  };

  /* Hooks */
  useEffect(() => {
    modelStateSet(props.model.data);
  }, [props.model.data]);

  useFetchEffect(async (didCancel) => {
    if (saveBtnLocked || props.saveBtnPressed <= saveBtnPressed) {
      return;
    }

    if (checkArticleDifference()) {
      modalStateSet(1);
      return;
    }

    await save();
  }, [props.saveBtnPressed]);

  useEffect(() => {
    if (props.externalModelChange) {
      modelStateSet(props.externalModelChange);
    }
  }, [props.externalModelChange]);

  return <ArticleMultilayerPlanForm model={modelState}
                                    specification={props.specification}
                                    changeModel={m => changeModel(m)}>

    <EvReactModal showModal={modalState > 0}
                  contentLabel={modalHeader()}
                  modalClosed={() => saveConfirmed(false)}>
      {
        modalState === 1 ?
          <ArticleMultilayerDifferenceWarningModal
            response={confirmed => saveConfirmed(confirmed)}/> :
        modalState === 20 ?
          <ArticleMultilayerSaveBeforePdfModal
            response={confirmed => openPdfCheckArticle(confirmed)}/> :
        modalState === 21 ?
          <ArticleMultilayerDifferenceWarningModal
            ignoreAllowed={true}
            response={confirmed => openPdfSave(confirmed)}/> :
        modalState === 30 ?
          <ArticleMultilayerSaveBeforeRedirectModal
            response={confirmed => openArticleAndCopyMlCheckArticle(confirmed)}/> :
        modalState === 31 ?
          <ArticleMultilayerDifferenceWarningModal
            ignoreAllowed={true}
            response={confirmed => openArticleAndCopyMlSave(confirmed)}/> :
          ''
      }
    </EvReactModal>

    <ArticleMultilayerPlanHead model={modelState}
                               article={props.article}
                               specification={props.specification}
                               copyModelBtnPressed={props.copyModelBtnPressed}
                               changeModel={m => changeModel(m)}>
      {
        props.isAdmin ?
          <button type={'button'} className={'btn btn-default'} onClick={() => openHtmlNoCheck()}>
            Aufbauplan als HTML
          </button> :
          null
      }

      <button type={'button'} className={'btn btn-default'} onClick={() => openPdfCheckAll()}>
        Aufbauplan als Pdf
      </button>

      <button type={'button'} className={'btn btn-default'} onClick={() => openArticleAndCopyMlCheckAll()}>
        In Artikel übernehmen
      </button>
    </ArticleMultilayerPlanHead>
  </ArticleMultilayerPlanForm>;
}
