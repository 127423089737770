import {AxiosHttpClient} from '../../../envisia/auth/axios-http-client';
import {environment} from '../../../../environments/environment';
import {ArticleUsageStateForm} from './article-usage-state.model';
import {Article} from '../../../envisia/article/models/article.models';

export class ArticleUsageStateAxiosService {
  static upsert(oaNr: string, form: ArticleUsageStateForm):
    Promise<Article | null> {
    return AxiosHttpClient.post<Article | null>(
      `${environment.apiv4uri}article/${oaNr}/state/usage/`,
      form,
    );
  }
}
