import {useState} from 'react';
import {
  ArticleMeasurementAnalysisHook,
  ArticleMeasurementAnalysisState,
  ArticleMeasurement,
  ArticleMeasurementLister,
  ArticleMeasurementSummedAverage,
} from './article-measurement-analysis.model';
import {ArticleMeasurementAnalysisService} from './article-measurement-analysis.service';
import {
  ProductionStepQuestionnaireDataTypes,
} from '../../../lazy/article-config/production-step/production-step-questionnaire.models';

interface Props {
  oaNr: string;
  questionnaireId: number;
  articleValue?: string | number;
  list?: ArticleMeasurementLister | null | undefined;
  listUpdated?: (list: ArticleMeasurementLister) => void;
}

export function useArticleMeasurementAnalysisState(props: Props): ArticleMeasurementAnalysisHook {
  const getSelectedSum: (
    list?: ArticleMeasurementLister | null | undefined,
  ) => ArticleMeasurementSummedAverage = (list) => {
    return list?.sums?.find(q => q.article_value === (props.articleValue?.toString() ?? ''));
  };

  const [state, setState] = useState<ArticleMeasurementAnalysisState>({
    list: props.list ?? null,
    modalState: 'closed',
    articleValue: props.articleValue,
    selected: null,
    selectedSum: getSelectedSum(props.list ?? null),
    isInteractionLocked: false,
    dataType: ProductionStepQuestionnaireDataTypes.getType(props.list?.questionnaire?.data_type),
  });

  const reload: (modalOpen?: boolean) => void = async (modalOpen) => {
    try {
      const list = await ArticleMeasurementAnalysisService.list(
        props.oaNr,
        props.questionnaireId,
      );
      props?.listUpdated(list.data);
      setState({
        ...state,
        list: list.data,
        articleValue: props.articleValue,
        selectedSum: getSelectedSum(list.data),
        modalState: modalOpen === true ? 'list' : state.modalState,
        dataType: ProductionStepQuestionnaireDataTypes.getType(list.data.questionnaire?.data_type),
      });
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteReading: () => void = async () => {
    try {
      await ArticleMeasurementAnalysisService.delete(props.oaNr, props.questionnaireId, state.selected.id);
      reload(true);
    } catch (ex) {
      console.error(ex);
    }
  };

  const setModalState: (modalState: 'closed' | 'list') => void = (modalState) => {
    setState({...state, modalState: modalState});
  };

  const setModalStateConfirmDelete: (questionnaireResult: ArticleMeasurement) => void = (questionnaireResult) => {
    setState({...state, modalState: 'confirm-delete', selected: questionnaireResult});
  };

  return {
    state: state,
    setModalState: setModalState,
    reload: reload,
    setModalStateConfirmDelete: setModalStateConfirmDelete,
    deleteReading: deleteReading,
  };
}
