import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BigFetchWithPosition} from '../../models/fetch.models';
import {LoadingButtonEvent} from '../../../../common/loading-button/loading-button.component';
import {DeliveryForm, InventoryForm} from '../../models/delivery.models';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import Helper from '../../../../helper/helper';
import {HttpErrorResponse} from '@angular/common/http';
import {DeliveryService} from '../../workflow-services/delivery.service';
import {Workflow} from '../../models/workflow.models';
import {DeliveryModalService} from '../modals/delivery-modal.service';
import {
  DeliveryCreateModalData,
  DeliveryLabelFormGroup,
  DeliveryFormGroup,
  DeliveryInventoryFormGroup,
  DeliveryInventoryFormListElement,
} from './delivery-create-modal.models';
import {debug} from '../../../../helper/debug.func';
import {InventoryStorage} from '../../../article/inventory/inventory.models';
import {noop} from '../../../../helper/noop';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NumberHelper} from '../../../../helper/number-helper';

@Component({
  selector: 'delivery-create-modal-form-inventory',
  templateUrl: './delivery-create-modal-form-inventory.component.html',
})
export class DeliveryCreateModalFormInventoryComponent implements OnInit, OnDestroy {
  @Input() data: DeliveryCreateModalData;
  @Input() fetches: BigFetchWithPosition[];
  @Input() storage: InventoryStorage[];
  @Input() workflow: Workflow;
  quantityRequired = 0;
  quantityLeft = 0;
  form: FormGroup<DeliveryFormGroup>;
  formSubscription?: Subscription;
  errors: { [key: string]: any; } = {};
  storageData: { [key: number]: InventoryStorage } = {};

  constructor(private fb: FormBuilder,
              private deliveryModalService: DeliveryModalService,
              private deliveryService: DeliveryService,
              private activeModal: NgbActiveModal) {
  }

  get inventoryArray(): FormArray<FormGroup<DeliveryInventoryFormGroup>> {
    return this.form.get('inventories') as FormArray<FormGroup<DeliveryInventoryFormGroup>>;
  }

  get inventoryFormArrayMapped(): DeliveryInventoryFormListElement[] {
    const formArray = this.inventoryArray;
    return formArray.value.map((_, i) => ({
      index: i,
      element: this.storage[i],
      form: formArray.at(i),
    }));
  }

  ngOnInit() {
    this.quantityRequired = this.fetches.reduceRight((sum, fetch) => {
      return sum + NumberHelper.saveParseInteger(fetch.positions.find(q => q.typ === 'article')?.quanity) ?? 0;
    }, 0);

    this.quantityLeft = this.quantityRequired;

    const formArray = this.initInventoryArrayAndData();
    this.form = this.fb.group<DeliveryFormGroup>({
      'inventories': formArray,
      'note': new FormControl<string>(''),
      'labels': this.fb.array<FormGroup<DeliveryLabelFormGroup>>([]),
    });

    this.formSubscription = this.form.get('inventories')
      .valueChanges
      .pipe(debounceTime(700))
      .subscribe(inventories => {
        this.quantityLeft = (
          this.quantityRequired -
          inventories.reduceRight((sum, inventory) => {
            return sum + (inventory.quantity ?? 0);
          }, 0)
        );
      });
  }

  ngOnDestroy(): void {
    debug('ngOnDestroy: Delivery Create Modal Form Inventory');
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  submit(clb: LoadingButtonEvent): void {
    this.errors = {};
    const formValues = this.form.value;
    const inventoryArray: InventoryForm[] = formValues.inventories
      .sort((a, b) => a.index - b.index)
      .filter(q => q.quantity > 0)
      .map(value => ({
        index: value.index,
        date_code: value.date_code,
        storage_bin_name: value.storage_bin_name,
        storage_bin_number: value.storage_bin_number,
        workflow_id: value.workflow_id,
        quantity: value.quantity,
      }));

    const labelArray = formValues.labels.map(label => ({
      index: label.index,
      weight: NumberHelper.saveParseInteger(label.weight),
    }));

    if (labelArray.filter(label => label.weight <= 0).length > 0) {
      labelArray.forEach(label => {
        if (!label.weight || label.weight <= 0) {
          this.errors[`labels.${label.index}.weight`] = 'weight-not-sufficient';
        }
      });

      clb.callback(false);
      return;
    }

    const form: DeliveryForm = {
      nr: this.data.nr,
      delivery_date: this.data.delivery_date,
      hint: this.data.hint,
      fetch: this.data.fetch,
      inventory: inventoryArray,
      labels: labelArray,
      note: this.form.value.note,
    };

    this.deliveryService.create(this.workflow.id, form).subscribe(response => {
      clb.callback(false);
      this.activeModal.close(response);
    }, (response: HttpErrorResponse) => {
      clb.callback(false);
      this.deliveryModalService.openErrorModal(response.error.error);
    });
  }

  private initInventoryArrayAndData(): FormArray<FormGroup<DeliveryInventoryFormGroup>> {
    const array = this.fb.array<FormGroup<DeliveryInventoryFormGroup>>([]);
    this.storage.forEach((value, index) => {
      this.storageData[index] = value;
      array.push(this.fb.group<DeliveryInventoryFormGroup>({
        index: new FormControl<number>(index),
        date_code: new FormControl<string>(value.date_code),
        storage_bin_name: new FormControl<string>(value.storage_bin_name),
        storage_bin_number: new FormControl<number>(value.storage_bin_number),
        workflow_id: new FormControl<number>(value.workflow_id),
        quantity: new FormControl<number>(0, Validators.compose([this.validateFormControl(value)])),
      }));
    });
    return array;
  }

  private validateFormControl(value: InventoryStorage): ValidatorFn {
    return (control: AbstractControl) => {
      if (Helper.undefined(control.value) || value.quantity - control.value >= 0) {
        return null;
      }
      return {'invalid.count': 'quantity should not go below zero'};
    };
  }

  get labelFormArray(): FormArray<FormGroup<DeliveryLabelFormGroup>> {
    return this.form.get('labels') as FormArray<FormGroup<DeliveryLabelFormGroup>>;
  }

  get labelFormArrayMapped(): FormGroup<DeliveryLabelFormGroup>[] {
    const formArray = this.labelFormArray;
    return formArray.value.map((_, i) => formArray.at(i));
  }

  trackByFnInventories(index: number, item: DeliveryInventoryFormListElement): number {
    noop(this);
    return item.form.value.index;
  }

  trackByFnLabels(index: number, item: FormGroup<DeliveryLabelFormGroup>): number {
    noop(this);
    return item.value.index;
  }

  addLabel(): void {
    this.labelFormArray.push(this.fb.group<DeliveryLabelFormGroup>({
      index: new FormControl<number>(this.labelFormArray.length + 1),
      weight: new FormControl<string>(''),
    }));
  }

  removeLabel(form: FormGroup<DeliveryLabelFormGroup>): void {
    const index = this.form.value.labels.findIndex(q => q.index === form.value.index);
    this.labelFormArray.removeAt(index);
    this.form.value.labels.forEach((_, i) => {
      this.labelFormArray.at(i).patchValue({index: i + 1}, {emitEvent: false});
    });
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }
}
