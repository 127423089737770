export class ArticleMultilayerPlanConstants {
  static copperThickness: number[] = [0, 12, 18, 35, 70, 105, 140, 210, 235];
  static prepregTissues: string[] = ['106', '1080', '2116', '7628', '106HR', '1080HR', '2116HR', '7628HR'];

  static mapLacquerThickness(thickness: number): number {
    switch (thickness) {
      case 12: return 30;
      case 18: return 30;
      case 35: return 30;
      case 50: return 40;
      case 70: return 40;
      case 105: return 55;
      case 140: return 55;
      case 175: return 60;
      case 210: return 70;
      case 235: return 70;
      default: return 0;
    }
  }

  static mapPrepregDataToThickness(value: string): number {
    switch (value) {
      case '106':
        return 57;
      case '1080':
        return 76;
      case '2116':
        return 121;
      case '7628':
        return 197;
      case '106HR':
        return 60;
      case '1080HR':
        return 90;
      case '2116HR':
        return 140;
      case '7628HR':
        return 220;
      default:
        return null;
    }
  }

  // Tries to find the next smaller thickness
  static mapCuOuterFromCuThickness(cuThickness: number | null): number | null {
    if (cuThickness === null) {
      return null;
    }

    const cuThicknessIndex = ArticleMultilayerPlanConstants.copperThickness.findIndex(ct => ct === cuThickness);
    if (cuThicknessIndex > 0) {
      return ArticleMultilayerPlanConstants.copperThickness[cuThicknessIndex - 1];
    }

    return 0;
  }

  // Tries to find the next larger thickness
  static mapCuThicknessFromCuOuter(cuOuter: number | null): number | null {
    if (cuOuter === null) {
      return null;
    }
    const cuOuterIndex = ArticleMultilayerPlanConstants.copperThickness.findIndex(ct => ct === cuOuter);
    if (cuOuterIndex + 1 < ArticleMultilayerPlanConstants.copperThickness.length) {
      return ArticleMultilayerPlanConstants.copperThickness[cuOuterIndex + 1];
    }
    return 0;
  }
}
