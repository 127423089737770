import {ArticleSharedComponent} from './article-shared.component';
import {Article, ArticleStreamError, ArticleStreamValue} from '../../models/article.models';
import {Input, Directive, OnInit} from '@angular/core';
import {ArticleSpecification} from '../../models/article-specification.model';
import {ArticleCalculatorService} from '../services/article-calculator.service';
import {DateHelper} from '../../../../helper/date-helper';
import {ArticleUpdateService} from '../../component-service/article-update.service';
import {UntypedFormControl} from '@angular/forms';
import {debug} from '../../../../helper/debug.func';
import {ArticleMultilayerPlan} from '../multilayer-plan/article-multilayer-plan.models';
import {
  ArticleMultilayerPlanHolderCompareHelper
} from '../multilayer-plan/article-multilayer-plan-holder-compare-helper';
import {StateService} from '@uirouter/angular';
import {NumberHelper} from '../../../../helper/number-helper';
import {ArticleMultilayerPlanConstants} from '../multilayer-plan/article-multilayer-plan-constants';

@Directive()
export abstract class ArticleUpdateSharedComponent extends ArticleSharedComponent implements OnInit {
  @Input() public article: Article;
  @Input() public multilayerPlan?: ArticleMultilayerPlan;
  @Input() public copyMl = false;

  protected additionalFields: string[] = ['sds_nr'];

  protected abstract articleUpdateService: ArticleUpdateService;
  protected abstract stateService: StateService;

  markedMl?: {[key: string]: {original: any, ml: any}} = null;
  errorsMl?: string = null;

  ngOnInit() {
    super.ngOnInit();
    if (this.copyMl && !!this.multilayerPlan?.data) {
      this.setByMultilayer();
    }
  }

  protected additionalDefaultValue(name: string): { value: string | number | undefined, disabled: boolean } {
    if (name === 'sds_nr') {
      return {value: this.article.sds_nr, disabled: !!this.article.sds_nr};
    } else {
      return {value: undefined, disabled: true};
    }
  }

  protected defaultValue(value: ArticleSpecification): { key: string; value: string | number | undefined }[] {
    let currentValue = [];
    if (value.dateType !== 'NOVALUE') {
      if (!value.multi) {
        let dataValue = this.article.data[value.name];
        if (dataValue === '') {
          dataValue = null;
        } else if (typeof dataValue === 'number') {
          // we convert all numbers to strings
          // because article-select would have problems
          // we feed numbers
          dataValue = '' + dataValue;
        }
        currentValue = [{key: value.name, value: dataValue}];
      } else {
        const dataValue = this.article.data[value.multi];
        const n = ArticleCalculatorService.executionExtractor(value.multi, dataValue);
        if (n !== 0) {
          for (let i = 1; i <= n; i++) {
            const realName = value.name + '_' + i;
            let realValue = this.article.data[realName];
            if (realValue === '') {
              realValue = null;
            } else if (typeof realValue === 'number') {
              // we convert all numbers to strings
              // because article-select would have problems
              // we feed numbers
              realValue = '' + realValue;
            }
            currentValue.push({key: realName, value: realValue});
          }
        }
      }
    }
    return currentValue;
  }

  protected articleValue(name: string): any | null | undefined {
    return this.article.data[name];
  }

  save(): void {
    debug('article save called');
    const formValue = this.form.value;
    // manually enforce saveLock
    this.saveLock = true;
    this.errors = {};
    this.articleUpdateService.update(
      this.article,
      formValue.sds_nr,
      formValue,
      {blocking_notice: this.options}
    ).subscribe(
      (stream) => {
        if (stream instanceof ArticleStreamValue) { // success
          debug('article next');
          // reinitalizes
          this.article = stream.article;
          this.ngOnDestroy();
          this.ngOnInit();
          this.newArticleValue(stream.article);
          this.resetMultilayerPlanData();
        } else if (stream instanceof ArticleStreamError) { // failure
          debug('article error');
          const response = stream.response;
          if (!!response.error && response.headers.get('Content-Type') === 'application/json') {
            if (response.error.objects) {
              this.errors = response.error.objects;
            } else {
              this.errors = response.error;
            }
          }
          this.changeSaveLock();
        }
      },
      (n) => debug('something impossible happened article error', n),
      () => {
        debug('article complete');
        this.changeSaveLock();
      }
    );
  }

  deleted(): void {
    this.article.date_deleted = DateHelper.format(new Date());
    this.newArticleValue(this.article);
  }

  recovered(article: Article): void {
    this.article.date_deleted = null;
    console.log('Article Recovered:', article);
    this.newArticleValue(article);
  }

  lockChange(value: number | null): void {
    this.article.locked = value;
    this.newArticleValue(this.article);
  }

  generatedSds(article: Article): void {
    this.article = article;
    const formControl = this.form.get('sds_nr');
    if (formControl !== null) {
      (formControl as UntypedFormControl).patchValue(this.article.sds_nr, {emitEvent: false});
    }
    this.newArticleValue(article);
  }

  generatedSister(article: Article): void {
    this.article.sister = article.sds_nr;
    this.newArticleValue(article);
  }

  updateState(state: string): void {
    this.article.change_state = state;
    this.newArticleValue(this.article);
  }

  protected changeSaveLock(): void {
    this.saveLock = this.refCount === 0 || this.article.locked !== null || !!this.article.date_deleted;
  }

  protected newArticleValue(article: Article): void {
  }

  setByMultilayer(): void {
    const diff = ArticleMultilayerPlanHolderCompareHelper.diffData(this.form.value, this.multilayerPlan.data);
    const cuOutside = diff.cu_outside.filter(d => d.differs);
    const cuCore = diff.cu_core.filter(d => d.differs);
    const cuInside = diff.cu_inside.filter(d => d.multilayer !== null);
    const coreThickness = diff.core_thickness.filter(d => d.multilayer !== null);

    // Check if both core cu_kash and cores array lengths match (should always be the case)
    if (diff.cu_inside.length !== diff.core_thickness.length) {
      this.errorsMl = 'core.mismatch';
      return;
    }

    // Check if HDI Field can be set
    let hdiType: string | null = null;
    const cuInsideNum = cuInside.length * 2;
    const cuOutsideNum = diff.cu_outside.length > 0 && diff.cu_outside.length % 2 === 0 ? diff.cu_outside.length : null;
    const cuCoreNum = diff.cu_core.length > 0 && diff.cu_core.length % 2 === 0 ? diff.cu_core.length : null;
    const sbuNum = !!diff.sbu_layers.multilayer && diff.sbu_layers.multilayer > 0 ? diff.sbu_layers.multilayer : null;
    if (sbuNum !== null && cuOutsideNum !== null) {
      const hasCoreSbuLayer = (diff.current.sbu_layers?.filter(p => p.is_core)?.length ?? 0) > 0;
      hdiType = (cuInsideNum + (hasCoreSbuLayer ? 0 : 2)).toString(10);
      for (let i = 0; i < sbuNum; i++) {
        hdiType = '1-' + hdiType + '-1';
      }
    }

    const execution: string | null = !cuOutsideNum ? null : `ML${(cuInsideNum + cuOutsideNum + (cuCoreNum ?? 0))}`;
    // Set null if layer count is not varied or if there is only one layer
    let mixedStructure: number | null = null;
    // Check cu inside (core cu kasch) differences
    let lastCuInside: number | null = null;
    if (cuInside.length > 0) {
      lastCuInside = cuInside[0]?.multilayer[0] ?? null;
      if (cuInside[0].multilayer[0] !== cuInside[0].multilayer[1]) {
        this.errorsMl = 'core.cu.inside.mismatch';
      }

      for (let i = 1; i < cuInside.length; i++) {
        if (cuInside[i].multilayer[0] !== cuInside[i].multilayer[1]) {
          this.errorsMl = 'core.cu.inside.mismatch';
        }

        if (lastCuInside !== cuInside[i].multilayer[0]) {
          mixedStructure = cuInside.length;
          break;
        }

        lastCuInside = cuInside[i].multilayer[0];
      }
    }

    // Check for core thickness differences, skip if mixed structure is already set
    let lastCoreThickness: number | null = null;
    if (coreThickness.length > 0 && mixedStructure === null) {
      lastCoreThickness = coreThickness[0]?.multilayer ?? null;
      for (let i = 1; i < coreThickness.length; i++) {
        if (lastCoreThickness !== coreThickness[i].multilayer) {
          mixedStructure = coreThickness.length;
          break;
        }

        lastCoreThickness = coreThickness[i].multilayer;
      }
    }

    mixedStructure = mixedStructure > 1 ? mixedStructure : null;

    // When mixed structure is null all cores are uniform
    let cuInsideChanges: number[] = [];
    let coreThicknessChanges: number[] = [];
    if (mixedStructure !== null) {
      cuInsideChanges = diff.cu_inside.filter(d => !!d.multilayer).map(d => d.multilayer[0]);
      coreThicknessChanges = diff.core_thickness.filter(d => !!d.multilayer).map(d => d.multilayer);
    } else {
      if (!!diff.cu_inside[0]?.multilayer && NumberHelper.isDefined(diff.cu_inside[0].multilayer[0])) {
        cuInsideChanges.push(diff.cu_inside[0]?.multilayer[0]);
      }

      if (!!diff.core_thickness[0]?.multilayer) {
        coreThicknessChanges.push(diff.core_thickness[0]?.multilayer);
      }
    }

    // Gather values for form edit
    const changesBeforeOthers: { [key: string]: any } = {};
    if (((this.form.value.mixed_structure as string) ?? null) !== (mixedStructure?.toString(10) ?? null)) {
      changesBeforeOthers['mixed_structure'] = mixedStructure?.toString(10) ?? null;
    }

    if (diff.sbu_layers.differs) {
      if (!!sbuNum) {
        changesBeforeOthers['sbu_layers'] = sbuNum.toString(10);
      } else {
        changesBeforeOthers['sbu_layers'] = null;
      }
    }

    const changes: { [key: string]: any } = {...changesBeforeOthers};
    if (mixedStructure === null || mixedStructure === 1) {
      changes['cu_inside_1'] = cuInsideChanges[0]?.toString(10);
      if (coreThicknessChanges[0]) {
        changes['core_thickness_1'] = NumberHelper.toCommaString(coreThicknessChanges[0] / 1000);
      } else {
        changes['core_thickness_1'] = null;
      }
    } else {
      for (let i = 0; i < mixedStructure; i++) {
        changes['cu_inside_' + (i + 1)] = cuInsideChanges[i]?.toString(10);
        if (coreThicknessChanges[i]) {
          changes['core_thickness_' + (i + 1)] = NumberHelper.toCommaString(coreThicknessChanges[i] / 1000);
        } else {
          changes['core_thickness_' + (i + 1)] = null;
        }
      }
    }

    if (cuOutside.length > 0) {
      changes['cu_outside'] = cuOutside[0].multilayer?.toString(10);
      const cuThickness = ArticleMultilayerPlanConstants.mapCuThicknessFromCuOuter(cuOutside[0]?.multilayer);
      changes['cu_thickness'] = cuThickness?.toString(10);
    }

    if (cuCore.length > 0) {
      changes['cu_core'] = cuCore[0].multilayer?.toString(10);
    }

    if (hdiType !== null) {
      changes['hdi_type'] = hdiType;
    }

    if (execution !== null) {
      changes['execution'] = execution;
    }

    // Keep copy of old and new values
    this.markedMl = {};
    Object.keys(changes).forEach(k => {
      this.markedMl[k] = {original: this.form.value[k], ml: changes[k]};
    });

    // mixed structure and sbu_layers needs to be updated prior, so that the core form controls are set correctly
    this.form.patchValue(changesBeforeOthers, {emitEvent: false});
    this.updateFields('mixed_structure', mixedStructure ?? 1);
    this.form.patchValue(changes);
  }

  resetMultilayerPlanData(): void {
    this.markedMl = null;
    this.copyMl = null;
    this.errorsMl = null;
  }

}
