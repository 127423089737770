<div class="buttonbar-view">

  <article-button-navigation [article]="article"
                             [options]="options"
                             [locked]="saveLock"
                             [validationErrors]="validationErrors"
                             (saveEmitter)="save()"
                             (deleteEmitter)="deleted()"
                             (recoverEmitter)="recovered($event)"
                             (generateSdsEmitter)="generatedSds($event)"
                             (generateSisterEmitter)="generatedSister($event)"
                             (lockChangeEmitter)="lockChange($event)">
  </article-button-navigation>

</div>

<div class="article-content">
  <div class="article-hide">&nbsp;</div>

  <nav class="article-fixed-head">
    <article-top-navigation [article]="article"
                            [multilayerPlan]="multilayerPlan">
    </article-top-navigation>

    <article-top [edit]="true" [article]="article" [topForm]="form" (updateState)="updateState($event)">
    </article-top>
  </nav>

  <article-locked-info [article]="article"></article-locked-info>

  <form class="form-horizontal small-input input-align-right small-form" [formGroup]="form">

    <div class="whitebox red_alert align-center" *ngIf="copyMl">
      <i class="fa fa-exclamation-triangle" style="color: red;"></i>
      <ng-container [ngSwitch]="errorsMl">
        <ng-container *ngSwitchCase="'core.mismatch'">
          <strong>
            Der Multilayeraufbauplan hat einen Fehler im Aufbau.
          </strong>
          Bitte prüfen.
        </ng-container>

        <ng-container *ngSwitchCase="'core.cu.inside.mismatch'">
          <strong>
            Mindestens ein Kern hat einen Cu-Kasch mit verschiedenen Dicken.
          </strong>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <strong>
            Daten aus dem Multilayeraufbauplan wurden übernommen.
          </strong>
          Die Markierten Felder zeigen die Änderungen an.
        </ng-container>
      </ng-container>
    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">
      <div class="col-lg-7 whitebox">

        <h4>Ausführung LP</h4>
        <div class="row">
          <div class="col-lg-6">

            <article-select label="LP Typ"
                            model="execution"
                            [form]="form"
                            [markedML]="markedMl"
                            [specification]="specification"
                            [error]="errors['execution']"></article-select>

            <!-- microvias group -->
            <hr>
            <article-hdi-form [form]="form"
                              [errors]="errors"
                              [refCount]="refCount"
                              [markedML]="markedMl"
                              [specification]="specification">
            </article-hdi-form>
          </div>

          <div class="col-lg-6">
            <h5>Kupferstruktur</h5>

            <article-input label="Min. Line" model="min_line" [form]="form" unit="µm" [error]="errors['surface']">
            </article-input>

            <article-input label="Min. Space" model="min_space" [form]="form" unit="µm" [error]="errors['surface']">
            </article-input>

            <article-input label="Min. Pad" model="min_pad" [form]="form" unit="mm" [error]="errors['surface']">
            </article-input>
          </div>
        </div>

      </div>

      <div class="col-lg-5 bluebox col-padding">

        <div class="row">
          <div class="col-lg-8">
            <article-select label="IPC-6012 Klasse" model="ipc_6012_class" [form]="form"
                            [specification]="specification" [error]="errors['ipc_6012_class']"></article-select>
          </div>

          <div class="col-lg-4">
          </div>
        </div>

      </div>

    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">
      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Basismaterial</h4>

        <div class="row">

          <div class="col-lg-6">
            <article-checkbox label="Aufbau Kundenvorgabe" model="customer_specification_structure"
                              [form]="form" [error]="errors['customer_specification_structure']"></article-checkbox>
            <article-input-optional label="Material Kundenvorgabe"
                                    modelCheckbox="customer_specification_material"
                                    modelInput="customer_specification_material_type"
                                    [article]="this.article"
                                    [form]="form"
                                    [errors]="errors">
            </article-input-optional>

            <article-checkbox label="Keine Basismaterialplanung" model="material_planning_no_base_material"
                              [form]="form" [error]="errors['material_planning_no_base_material']"></article-checkbox>

            <ng-container *ngIf="('article.internal_material_selection'|HasPerm)">
              <article-select label="Interne Materialvorgabe"
                              model="material_internal"
                              [form]="form"
                              [specification]="specification"
                              [refCount]="refCount"
                              [error]="errors['material_internal']">
              </article-select>
            </ng-container>

            <ng-container *ngIf="!('article.internal_material_selection'|HasPerm)">
              <article-label label="Interne Materialvorgabe"
                             model="material_internal"
                             [form]="form"
                             [specification]="specification"
                             [refCount]="refCount"
                             [materialSelect]="'material_internal'"
                             [plain]="true">
              </article-label>
            </ng-container>

            <article-select label="Hersteller / Bezeichnung" model="manufacturer" [form]="form"
                            [specification]="specification" [refCount]="refCount" [error]="errors['manufacturer']">
            </article-select>

            <!-- Labels RefCounted-->
            <article-label label="IPC-4101" model="ipc_4101" [form]="form" [specification]="specification"
                           [refCount]="refCount"></article-label>
            <article-label label="TG" model="tg" unit="C°" [form]="form" [specification]="specification"
                           [refCount]="refCount"></article-label>
            <article-label label="CTI >" model="cti" unit="V" [form]="form" [specification]="specification"
                           [refCount]="refCount"></article-label>
            <article-label label="Halogenfrei" model="halogen_free" [bool]="true" [form]="form"
                           [specification]="specification" [refCount]="refCount"></article-label>
            <article-label label="DE konform" model="de_compliant" [bool]="true" [form]="form"
                           [specification]="specification" [refCount]="refCount" [nullable]="true">
            </article-label>
            <article-label label="China konform" model="china_compliant" [bool]="true" [form]="form"
                           [specification]="specification" [refCount]="refCount"></article-label>
            <HR>
            <article-select label="Basis Cu Aussen"
                            model="cu_outside"
                            unit="µm"
                            [form]="form"
                            [markedML]="markedMl"
                            [specification]="specification"
                            [error]="errors['cu_outside']">
            </article-select>

            <article-select label="End Cu Aussen"
                            model="cu_thickness"
                            unit="µm"
                            [form]="form"
                            [markedML]="markedMl"
                            [specification]="specification"
                            [error]="errors['cu_thickness']">
            </article-select>

            <article-optional-group [form]="form"
                                    [specNames]="['cu_core']"
                                    [condition]="form.value.sbu_layers > 0">
              <ng-template>
                <article-select label="Basis Cu Kern"
                                model="cu_core"
                                unit="µm"
                                [form]="form"
                                [markedML]="markedMl"
                                [specification]="specification"
                                [error]="errors['cu_core']">
                </article-select>
              </ng-template>
            </article-optional-group>
          </div>

          <div class="col-lg-6">
            <h5>Kern ML</h5>

            <article-input label="Mischaufbau"
                           model="mixed_structure"
                           [form]="form"
                           [markedML]="markedMl"
                           [error]="errors['mixed_structure']">
            </article-input>

            <article-multi [form]="form" [specification]="specification" [refCount]="refCount" [errors]="errors">
              <ng-template let-item="item">
                <article-select [label]="'Kerndicke Kern ' + item.index"
                                [model]="'core_thickness_' + item.index"
                                [error]="item.errors['core_thickness_' + item.index]"
                                [form]="form"
                                [markedML]="markedMl"
                                [specification]="item.specification"
                                unit="mm"
                                [zero]="true">
                </article-select>

                <article-select [label]="'Cu Innen ' + item.index"
                                [model]="'cu_inside_' + item.index"
                                [error]="item.errors['cu_inside_' + item.index]"
                                [form]="form"
                                [markedML]="markedMl"
                                [specification]="item.specification"
                                unit="µm"
                                [zero]="true">
                </article-select>
              </ng-template>
            </article-multi>

            <HR>
            <h5>Dicke LP</h5>

            <article-select label="Materialdicke" model="material_thickness" unit="mm" [form]="form"
                            [specification]="specification" [error]="errors['material_thickness']"></article-select>

            <article-input label="Enddicke" model="endintensity" unit="mm" (changeEmitter)="aspectCalculated()" [form]="form"
                           [error]="errors['endintensity']"></article-input>

            <article-xy-input label="Toleranz (abweichend Standard)" x="-" y="+" unit="%"
                              model1="thickness_tolerance_minus" model2="thickness_tolerance_plus"
                              [form]="form" [error1]="errors['thickness_tolerance_minus']"
                              [error2]="errors['thickness_tolerance_plus']"></article-xy-input>
          </div>

        </div>
      </div>

      <div class="col-lg-5 bluebox col-padding">
        <article-checkbox label="DI Belichten Innenlagen" model="di_expose_internal_layer" [form]="form"
                          [error]="errors['di_expose_internal_layer']"></article-checkbox>

        <article-checkbox label="50µ Resist" model="resist" [form]="form" [error]="errors['resist']">
        </article-checkbox>

        <article-checkbox label="Innenlagen Produktspez. Skalieren" model="internal_layer_product_specific_scale"
                          [form]="form" [error]="errors['internal_layer_product_specific_scale']"></article-checkbox>

        <article-multi [form]="form" [specification]="specification" [refCount]="refCount" [errors]="errors"
                       *ngIf="form.value['internal_layer_product_specific_scale']" model="execution">
          <ng-template let-item="item">
            <div class="row">

              <article-xy-input class="col-sm-8"
                                [label]="'Kern ' + item.index"
                                [form]="form"
                                [model1]="'core_x_' + item.index"
                                [model2]="'core_y_' + item.index">
              </article-xy-input>

              <div class="col-sm-4">&nbsp;</div>
            </div>
          </ng-template>
        </article-multi>

        <HR>

        <article-checkbox label="Pressen nach Aufbauplan" model="structure_sds_development_plan" [form]="form"
                          [error]="errors['structure_sds_development_plan']"></article-checkbox>

        <article-select label="Pressprogramm" model="press_program" [form]="form"
                        [error]="errors['press_program']" [specification]="specification"></article-select>
        <article-input label="Dicke Pressrohling" model="thickness_press_blank" unit="mm"
                       [form]="form" [error]="errors['thickness_press_blank']"></article-input>
      </div>

    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">

      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Format</h4>

        <div class="row">
          <div class="col-lg-6">
            <article-xy-input label="LP-Größe" unit="mm" model1="board_size1" model2="board_size2" [form]="form"
                              (changeEmitter)="calculated($event)" [specification]="specification"
                              [error1]="errors['board_size1']" [error2]="errors['board_size2']"></article-xy-input>

            <article-xy-input label="Liefernutzen-Gr." unit="mm" model1="utilize_size1" model2="utilize_size2"
                              (changeEmitter)="calculated($event)" [form]="form" [specification]="specification"
                              [error1]="errors['utilize_size1']" [error2]="errors['utilize_size2']">
            </article-xy-input>

            <article-input-calc label1="LP/Fe.Nu." label2="LP/Liefernu." label3="Liefernu./Fe.Nu." model1="utilize1" model2="utilize2" model3="utilize_sum"
                                (changeEmitter)="calculated($event)" [form]="form" [error1]="errors['utilize1']"
                                [error2]="errors['utilize2']" [error3]="errors['utilize_sum']"></article-input-calc>
          </div>

          <div class="col-lg-6">
            <article-input label="LP-Fläche" model="surface" unit="dm²" [form]="form" [error]="errors['surface']" [isDisabled]="true">
            </article-input>

            <article-input label="Liefernutzenfläche" model="utilize_area" unit="dm²" [form]="form"
                           [error]="errors['utilize_area']" [isDisabled]="true"></article-input>
          </div>

        </div>

      </div>

      <div class="col-lg-5 bluebox col-padding">
        <article-select label="Fertigungsnutzenformat" model="utilize_format" [error]="errors['utilize_format']"
                        [specification]="specification" [form]="form"></article-select>
      </div>
    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">

      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Mech. Bearbeitung</h4>

        <div class="row">

          <div class="col-lg-6">
            <article-input label="Min. Bohrdurchmesser" model="drilling" unit="mm" [form]="form"
                           [error]="errors['drilling']" (changeEmitter)="aspectCalculated()"></article-input>

            <article-input label="Aspect Ratio" model="aspect_ratio" [form]="form" [error]="errors['aspect_ratio']" [isDisabled]="true">
            </article-input>
            <article-input label="Anzahl Bohrwerkzeuge" model="drilling_tool_quanity" [form]="form"
                           [error]="errors['drilling_tool_quanity']"></article-input>
            <article-input label="Anzahl Bohr. pro Lp" model="drilldensity" [form]="form"
                           [error]="errors['drilldensity']"></article-input>

            <div class="article-form-item">
              <label class="article-form-item-label">Bohrdichte pro LP</label>
              <div class="article-form-item-data">
                <div class="article-form-data-content">
                  {{ drillingDensityPerLp() }}
                </div>
              </div>
            </div>

            <article-input label="Fräsweg pro LP." model="milling_way_per_lp" unit="mm" [form]="form"
                           [error]="errors['milling_way_per_lp']"></article-input>
            <HR>

            <article-checkbox label="Einspresstechnik" model="injection_technique" [form]="form"
                              [error]="errors['injection_technique']"></article-checkbox>

            <article-select label="Einspresstechnik Norm" model="injection_technique_standard"
                            [specification]="specification" [form]="form" [error]="errors['injection_technique']">
            </article-select>

            <HR>

            <article-checkbox label="Ritzen" model="scratch" [form]="form" [error]="errors['scratch']">
            </article-checkbox>

            <article-input label="Ritzen Reststeg (abweichend Standard)" model="scratch_landing_stage" unit="mm"
                           [form]="form" [error]="errors['scratch_landing_stage']"></article-input>
          </div>
          <div class="col-lg-6">
            <article-input label="Min. Frasdurchmesser" model="min_milling_diameter" unit="mm"
                           [form]="form" [error]="errors['min_milling_diameter']"></article-input>

            <article-ab-checkbox label="Blind Via" model1="blind_hole_tech_ls" model2="blind_hole_tech_ds" [form]="form"
                                 [error1]="errors['blind_hole_tech_ls']" [error2]="errors['blind_hole_tech_ds']">
            </article-ab-checkbox>
            <article-checkbox label="Buried Via" model="buried_vias" model2="buried_vias_text" [form]="form"
                              [error]="errors['buried_vias']" [error2]="errors['buried_vias_text']">
            </article-checkbox>
            <HR>
            <h5>Sonderbearbeitung</h5>

            <article-ab-checkbox label="Fasen" model1="bevel_a" model2="bevel_b" [form]="form"
                                 [error1]="errors['bevel_a']" [error2]="errors['bevel_b']">
            </article-ab-checkbox>

            <article-select label="Fasen Winkel" model="bevel_angle" [form]="form" [specification]="specification"
                            [error]="errors['bevel_angle']"></article-select>

            <article-ab-checkbox label="Senken" model1="cut_a" model2="cut_b" [form]="form"
                                 [error1]="errors['cut_a']" [error2]="errors['cut_b']"></article-ab-checkbox>

            <article-ab-checkbox label="Senken metallisiert" model1="cut_metallised_a" model2="cut_metallised_b"
                                 [error1]="errors['cut_metallised_a']" [error2]="errors['cut_metallised_b']"
                                 [form]="form"></article-ab-checkbox>

            <article-input label="Senkungen" model="lowerings" unit="St. / Lp" [form]="form"
                           [error]="errors['lowerings']">
            </article-input>
          </div>
        </div>
      </div>

      <div class="col-lg-5 bluebox col-padding flex flex-col justify-between">

        <div class="row w-full">
          <div class="col-lg-6">
            <article-checkbox label="Tiefenfräsen" model="deep_mill" [form]="form" [error]="errors['deep_mill']">
            </article-checkbox>

            <article-checkbox label="DK-Fräsen" model="dk_mill" [form]="form" [error]="errors['dk_mill']">
            </article-checkbox>

            <article-checkbox label="Fräsen vor Ätzen" model="mill_v_acid_treat" [form]="form"
                              [error]="errors['mill_v_acid_treat']"></article-checkbox>

            <article-checkbox label="Kontaktkamm" model="contact_fanning_strip" [form]="form"
                              [error]="errors['contact_fanning_strip']"></article-checkbox>

            <article-checkbox label="Eingeengte Toleranzen Fräsen" model="narrowly_tolerance_milling" [form]="form"
                              [error]="errors['narrowly_tolerance_milling']"></article-checkbox>
          </div>
          <div class="col-lg-6">
            <article-input label="Bohrpaket" model="bohr_packet" [form]="form" [error]="errors['bohr_packet']">
            </article-input>

            <article-input label="Fräspaket" model="mill_packet" [form]="form" [error]="errors['mill_packet']">
            </article-input>

            <article-checkbox label="Decklage Fräsen" model="top_layer_mill" [form]="form"
                              [error]="errors['top_layer_mill']"></article-checkbox>
          </div>
        </div>

        <div class="row w-full">
          <div class="col-lg-6 h-full flex flex-col justify-end">
            <article-analysis [oaNr]="article.oa_nr"
                              [measurementLists]="measurementLists"
                              [questionnaireId]="1"
                              (measurementListsUpdated)="measurementListUpdate($event)">
            </article-analysis>
          </div>

          <div class="col-lg-6">
            <article-analysis-sum label="Gesamtbohrzeit"
                                  [oaNr]="article.oa_nr"
                                  [measurementLists]="measurementLists"
                                  [questionnaireIds]="[3, 4, 5, 6, 7, 8, 9]"
                                  (measurementListsUpdated)="measurementListUpdate($event)">
            </article-analysis-sum>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">

      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Via Handling</h4>

        <div class="row">
          <div class="col-lg-12">
            <article-via-handling-form [form]="form"
                                       [errors]="errors"
                                       [specification]="specification"
                                       [refCount]="refCount"
                                       (setAutomatedChange)="setAutomatedChange($event)">
            </article-via-handling-form>
          </div>
        </div>
      </div>

      <div class="col-lg-5 bluebox col-padding"></div>
    </div>

    <article-electroplating-form title="Galvanik"
                                 [form]="form" [refCount]="refCount" [specification]="specification" [errors]="errors">
    </article-electroplating-form>

    <article-optional-group [form]="form"
                            [specNames]="specNamesPlatingCore"
                            [condition]="form.value.sbu_layers > 0">
      <ng-template>
        <article-electroplating-form title="Galvanik Kern"
                                     [form]="form"
                                     [refCount]="refCount"
                                     [specification]="specification"
                                     [errors]="errors">
        </article-electroplating-form>
      </ng-template>
    </article-optional-group>

    <article-optional-group [form]="form"
                            [specNames]="specNamesPlatingVia"
                            [condition]="(form.value.via_handling === 'Filled and Capped Via (IPC-4761 VII)')">
      <ng-template>
        <article-electroplating-form title="Galvanik Via"
                                     [form]="form"
                                     [refCount]="refCount"
                                     [specification]="specification"
                                     [errors]="errors">
        </article-electroplating-form>
      </ng-template>
    </article-optional-group>

    <div class="row row-no-margin top-inner-buffer row-flexbox">

      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Oberfläche</h4>

        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-12">
                <article-select label="Oberfläche" model="surface_area"
                                [form]="form"
                                [specification]="specification"
                                [error]="errors['surface_area']">
                </article-select>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <article-checkbox label="Galv. NiAu"
                                  model="electroplating_niau"
                                  [form]="form"
                                  [error]="errors['electroplating_niau']">
                </article-checkbox>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <article-input label="Sonstiges" model="surface_excise_dues" [form]="form"
                           [error]="errors['surface_excise_dues']"></article-input>
          </div>
        </div>

      </div>

      <div class="col-lg-5 bluebox col-padding">
        <div class="row">
          <div class="col-lg-6">
            <article-xy-input label="ENIG-Fläche" unit="dm²" x="A" y="B"
                              model1="enig_surface_a" model2="enig_surface_b" [form]="form"
                              [error1]="errors['enig_surface_a']" [error2]="errors['enig_surface_b']">
            </article-xy-input>

            <article-xy-input label="ENIG-Schichtdicke" model1="thickness_enig_ni" model2="thickness_enig_au"
                              unit="µm" x="Ni" y="Au" [form]="form"
                              [error1]="errors['thickness_enig_ni']" [error2]="errors['thickness_enig_au']">
            </article-xy-input>
            <article-select label="Subcontracting" model="subcontracting" [form]="form" [specification]="specification"
                            [error]="errors['subcontracting']"></article-select>
          </div>
          <div class="col-lg-6">
            <article-xy-input label="Galv. NiAu Fläche" model1="galv_niau_surface_a" model2="galv_niau_surface_b"
                              unit="dm²" x="A" y="B" [form]="form"
                              [error1]="errors['galv_niau_surface_a']" [error2]="errors['galv_niau_surface_b']">
            </article-xy-input>
            <article-xy-input label="Galv. AU-Schichtdicke" model1="thickness_galv_ni" model2="thickness_galv_au"
                              unit="µm" x="Ni" y="Au" [form]="form"
                              [error1]="errors['thickness_galv_ni']" [error2]="errors['thickness_galv_au']">
            </article-xy-input>
          </div>
        </div>

        <HR>
        <h5>HAL Parameter</h5>

        <div class="row">
          <div class="col-lg-6">
            <article-input label="Haupttauchzeit" model="main_dive_time" unit="s" [form]="form"
                           [error]="errors['main_dive_time']"></article-input>
            <article-input label="Abblasdruck vorne" model="blow_up_pressure_front" unit="bar" [form]="form"
                           [error]="errors['blow_up_pressure_front']"></article-input>
            <article-input label="Abblasdruck hinten" model="blow_up_pressure_back" unit="bar" [form]="form"
                           [error]="errors['blow_up_pressure_back']"></article-input>
          </div>

          <div class="col-lg-6">
            <article-checkbox label="Doppelt Tauchen" model="double_dive" [form]="form" [error]="errors['double_dive']">
            </article-checkbox>
            <article-input label="Vortauchzeit" model="pre_dive_time" unit="s" [form]="form"
                           [error]="errors['pre_dive_time']"></article-input>
            <article-checkbox label="Vibration" model="vibration" [form]="form" [error]="errors['vibration']">
            </article-checkbox>
            <article-checkbox label="Chem. Sn vor HAL" model="chem_sn_before_hal" [form]="form"
                              [error]="errors['chem_sn_before_hal']"></article-checkbox>
          </div>
        </div>

      </div>
    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">

      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Lötstoppmaske</h4>

        <div class="row">
          <div class="col-lg-6">
            <article-select label="Lötstopplack A" model="solder_resist_a" [form]="form" [specification]="specification"
                            [error]="errors['solder_resist_a']"></article-select>
            <article-select label="Lötstopplack B" model="solder_resist_b" [form]="form" [specification]="specification"
                            [error]="errors['solder_resist_b']"></article-select>
            <article-input-optional label="Lack Kundenvorgabe"
                                    modelCheckbox="customer_specification_solder_resist"
                                    modelInput="customer_specification_solder_resist_type"
                                    [article]="this.article"
                                    [form]="form"
                                    [errors]="errors">
            </article-input-optional>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>

      <div class="col-lg-5 bluebox col-padding">
        <div class="row">
          <div class="col-lg-6">
            <article-select label="Seite zuerst" model="solder_resist_side_first" [form]="form"
                            [specification]="specification" [error]="errors['solder_resist_side_first']">
            </article-select>

            <article-checkbox label="Siebdruck" model="silk_screen" [form]="form" [error]="errors['silk_screen']">
            </article-checkbox>
            <article-checkbox label="Erste Beschichtung" model="first_coating" [bold]="true" [form]="form"
                              [error]="errors['first_coating']"></article-checkbox>

            <article-input label="Naß Lack Gewicht" model="first_wet_lack_weight" unit="g/m²" [form]="form"
                           [error]="errors['first_coating']"></article-input>

            <article-input label="Gießgeschwindigkeit" model="first_mold_velocity" unit="m/min" [form]="form"
                           [error]="errors['first_mold_velocity']"></article-input>

            <article-select label="Gießrichtung" model="first_mold_direction" [form]="form"
                            [error]="errors['first_mold_direction']" [specification]="specification"></article-select>
            <article-checkbox label="Einseitig Gießen" model="first_partial_pouring" [form]="form"
                              [error]="errors['first_partial_pouring']"></article-checkbox>
          </div>
          <div class="col-lg-6">
            <article-checkbox label="Zweite Beschichtung" model="second_coating" [bold]="true" [form]="form"
                              [error]="errors['second_coating']"></article-checkbox>

            <article-input label="Naß Lack Gewicht" model="second_wet_lack_weight" unit="g/m²" [form]="form"
                           [error]="errors['second_wet_lack_weight']"></article-input>

            <article-input label="Gießgeschwindigkeit" model="second_mold_velocity" unit="m/min" [form]="form"
                           [error]="errors['second_mold_velocity']"></article-input>

            <article-select label="Gießrichtung" model="second_mold_direction" [form]="form"
                            [error]="errors['second_mold_direction']" [specification]="specification"></article-select>
            <article-checkbox label="Einseitig Gießen" model="second_partial_pouring" [form]="form"
                              [error]="errors['second_partial_pouring']"></article-checkbox>
          </div>
        </div>
      </div>

    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">

      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Zusatzdruck</h4>

        <div class="row">
          <div class="col-lg-6">
            <article-select label="Kennzeichnungsdruck A" model="best_pressure_a" [form]="form"
                            [specification]="specification" [error]="errors['best_pressure_a']"></article-select>
            <article-select label="Kennzeichnungsdruck B" model="best_pressure_b" [form]="form"
                            [specification]="specification" [error]="errors['best_pressure_b']"></article-select>
          </div>
          <div class="col-lg-6">
            <article-ab-checkbox label="Lötabdecklack" model1="protective_lacquer_ls" model2="protective_lacquer_ds"
                                 [error1]="errors['protective_lacquer_ls']" [error2]="errors['protective_lacquer_ds']"
                                 [form]="form"></article-ab-checkbox>

            <article-ab-checkbox label="Kaptonband" model1="capton_band_a" model2="capton_band_b"
                                 [error1]="errors['capton_band_a']" [error2]="errors['capton_band_b']" [form]="form">
            </article-ab-checkbox>
          </div>
        </div>
      </div>

      <div class="col-lg-5 bluebox col-padding">
        <div class="row">
          <div class="col-lg-6">
            <article-checkbox label="Nur Inkjet" model="only_inject" [form]="form" [error]="errors['only_inject']">
            </article-checkbox>
            <article-checkbox label="Hohe Auflösung" model="high_resolution" [form]="form"
                              [error]="errors['high_resolution']"></article-checkbox>
            <article-checkbox label="Nur Siebdruck" model="only_silkscreen" [form]="form"
                              [error]="errors['only_silkscreen']"></article-checkbox>

            <article-input label="Kaptonb. Anzahl Klebe" model="capton_band" unit="St. / Lp" [form]="form"
                           [error]="errors['capton_band']"></article-input>
          </div>
          <div class="col-lg-6">&nbsp;</div>
        </div>
      </div>
    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">

      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Kennzeichnungen</h4>

        <article-detail-matrix [form]="form" [specification]="specification" [errors]="errors"></article-detail-matrix>

      </div>
      <div class="col-lg-5 bluebox col-padding">&nbsp;</div>
    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">

      <div class="col-lg-7 whitebox col-height-hack">
        <h4>E-Test / Prüfen</h4>

        <div class="row">
          <div class="col-lg-6">
            <article-checkbox label="Einseitig" model="testing_1smd" [form]="form" [error]="errors['testing_1smd']">
            </article-checkbox>

            <article-checkbox label="Zweiseitig" model="testing_2smd" [form]="form" [error]="errors['testing_2smd']">
            </article-checkbox>
          </div>
          <div class="col-lg-6">&nbsp;</div>
        </div>
      </div>

      <div class="col-lg-5 bluebox col-padding">
        <div class="row">
          <div class="col-lg-6">
            <article-select label="Prüfmittel" model="measuring_device" [form]="form" [specification]="specification"
                            [error]="errors['measuring_device']"></article-select>

            <article-checkbox label="Prüfen Fertigungsnut." model="check_assembly_utilize" [form]="form"
                              [error]="errors['outer_plies_aoi']"></article-checkbox>

            <article-checkbox label="Aussenlagen AOI" model="outer_plies_aoi" [form]="form"
                              [error]="errors['outer_plies_aoi']"></article-checkbox>

            <article-checkbox label="Endprüfung AVI" model="final_inspection_aoi" [form]="form"
                              [error]="errors['final_inspection_aoi']"></article-checkbox>
          </div>
          <div class="col-lg-6">
            <article-analysis [oaNr]="article.oa_nr"
                              [measurementLists]="measurementLists"
                              [articleValue]="form.value.measuring_device"
                              [questionnaireId]="2"
                              (measurementListsUpdated)="measurementListUpdate($event)">
            </article-analysis>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-no-margin top-inner-buffer row-flexbox">
      <div class="col-lg-7 whitebox col-height-hack">
        <h4>Impedanz</h4>

        <div class="row">
          <div class="col-lg-6">

            <article-checkbox label="Impedanzkalkulation" model="impedance_calculation" [form]="form"
                              [error]="errors['impedance_calculation']"></article-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">

            <article-checkbox label="Impedanzprüfung" model="impedance_inspection" [form]="form"
                              [error]="errors['impedance_inspection']"></article-checkbox>
          </div>
          <div class="col-lg-6">

            <article-checkbox label="Messung jedes Los" model="impedance_every_lose" [form]="form"
                              [error]="errors['impedance_every_lose']"></article-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <article-input-string label="Bemerkung" model="impedance_comment"
                                  [form]="form"
                                  [error]="errors['impedance_comment']"></article-input-string>
          </div>
        </div>

      </div>

      <div class="col-lg-5 bluebox col-padding">&nbsp;</div>
    </div>

    <div class="row">
      <div class="col-lg-12">&nbsp;</div>
    </div>

  </form>

</div>
