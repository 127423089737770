import {ArticleUsageHook, ArticleUsageHookState} from './article.models';
import {useState} from 'react';
import {ArticleAxiosService} from './article-axios.service';

export function useArticle(): ArticleUsageHook {
  const [state, setState] = useState<ArticleUsageHookState>({
    article: null,
    isLoaded: false,
  });
  const get: (oaNr: string) => void = async (oaNr) => {
    try {
      const articleResult = await ArticleAxiosService.get(oaNr);
      if (!!articleResult.data) {
        setState({article: articleResult.data, isLoaded: true});
      } else {
        setState({...state, isLoaded: false});
      }
    } catch (e) {
      setState({...state, isLoaded: false});
    }
  };

  return {
    state: state,
    get: get,
  };
}
