/*
 * Copyright (C) 2017 envisia GmbH
 * All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User, UserCreateForm, UserInfo} from './user.models';
import {Observable} from 'rxjs';
import {EnvisiaObject, EnvisiaObjects} from '../../common/wrapper.models';
import {map} from 'rxjs/operators';
import {NewGroup, RightsCopyForm} from '../../lazy/user/model/user.model';
import {EnvisiaLocation} from '../../common/location/envisia-location';
import {HistoryEntry} from '../../lazy/user/model/history.model';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private http: HttpClient) {
  }

  create(form: UserCreateForm): Observable<User> {
    return this.http.post<EnvisiaObject<User>>(
      environment.apiv4uri + 'user/',
      form
    ).pipe(map(value => value.object));
  }

  list(): Observable<User[]> {
    return this.http.get<EnvisiaObjects<User>>(environment.apiv4uri + 'user/list/').pipe(map(value => value.objects));
  }

  changeGroup(username: string, groupName: string): Observable<User> {
    return this.http.put<EnvisiaObject<User>>(
      environment.apiv4uri + 'group/' + encodeURIComponent(username) + '/',
      {group: groupName}
    ).pipe(map(value => value.object));
  }

  info(username: string): Observable<UserInfo> {
    return this.http.get<EnvisiaObject<UserInfo>>(environment.apiv4uri + 'user/info/' + username + '/').pipe(
      map(value => value.object)
    );
  }

  usernameList(): Observable<string[]> {
    return this.http.get<EnvisiaObjects<string>>(environment.apiv4uri + 'user/username/list/').pipe(
      map(value => value.objects)
    );
  }

  listAllGroups(): Observable<NewGroup[]> {
    return this.http.get<EnvisiaObjects<NewGroup>>(`${environment.apiv4uri}rights/list/all/`).pipe(
      map(value => value.objects));
  }

  getRights(id: number): Observable<string[]> {
    return this.http.get<EnvisiaObject<string[]>>(`${environment.apiv4uri}user/rights/${id}/`).pipe(map(value => value.object));
  }

  updateRight(id: number, right: string): Observable<void> {
    const params = {right: right};
    return this.http.put<any>(`${environment.apiv4uri}rights/update/${id}/`,
      {}, {params: EnvisiaLocation.httpParamsWrapper(params)});
  }

  getHistory(id: number): Observable<HistoryEntry[]> {
    return this.http.get<EnvisiaObjects<HistoryEntry>>(`${environment.apiv4uri}user/rights/history/${id}/`).pipe(map(
      value => value.objects
    ));
  }

  copyRights(form: RightsCopyForm) {
    return this.http.post<Observable<void>>(`${environment.apiv4uri}rights/copy/`, form).pipe(map(
      () => {}
    ));
  }

  delete(userId: number): Observable<User> {
    return this.http.delete<User>(`${environment.apiv4uri}user/${userId}`);
  }

}
