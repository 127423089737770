import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MaterialArticleBookedInventory, MaterialWithCharge} from '../../../material/material-management.model';
import {HttpClient} from '@angular/common/http';
import {BusinessOrder} from '../models/business-order.models';
import {environment} from '../../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderMaterialService {

  constructor(private http: HttpClient) { }

  listMaterial(ba: BusinessOrder): Observable<MaterialArticleBookedInventory[]> {
    return this.http.get<MaterialArticleBookedInventory[]>(
      `/api/material/ba/${ba.id}/${ba.index}`
    );
  }

  getMaterialsWithCharge(boId: number, boIndex: number): Observable<MaterialWithCharge[]> {
    return this.http.get<MaterialWithCharge[]>(`${environment.apiv4uri}business/order/${boId}/${boIndex}/charge/last`);
  }
}
