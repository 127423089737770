import {useState} from 'react';
import {
  ArticleUsageModalHook,
  ArticleUsageModalState,
  ArticleUsageStateProps,
  UsageStateTypes,
} from './article-usage-state.model';
import {ArticleUsageStateAxiosService} from './article-usage-state-axios.service';

export function UseArticleUsageStateModal(props: ArticleUsageStateProps): ArticleUsageModalHook {
  const [state, setState] = useState<ArticleUsageModalState>({
    usageState: UsageStateTypes.getType(props.usageState),
    modalState: 'closed',
    isInteractionLocked: false,
  });

  const setModalState: (modalState: 'closed' | 'open') => void = (modalState) => {
    setState({...state, modalState: modalState});
  };

  const setUsageState: (usageState: number) => void = async (usageState) => {
    if (state.isInteractionLocked) {
      return;
    }

    setState({...state, isInteractionLocked: true});

    try {
      const article = await ArticleUsageStateAxiosService.upsert(props.oaNr, {usage_state: usageState});
      setState({
        usageState: UsageStateTypes.getType(article.usage_state),
        modalState: 'closed',
        isInteractionLocked: false,
      });

      if (!!props.usageStateChanged) {
        props.usageStateChanged(article.usage_state);
      }
    } catch (e) {
      setState({...state, isInteractionLocked: false});
    }
  };

  return {
    state: state,
    setModalState: setModalState,
    setUsageState: setUsageState,
  };
}
