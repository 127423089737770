import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductionStepCreateComponent} from './production-step-create.component';
import {ProductionStepEditComponent} from './production-step-edit.component';
import {ProductionStepArticleComponent} from './production-step-article.component';
import {ProductionStepEntity} from '../../../envisia/article/production/production.models';
import {ProductionStepService} from '../../../envisia/article/production-services/production-step.service';
import {ArticleSpecificationService} from '../../../envisia/article/services/article-specification.service';
import {AlertService} from '../../../common/alert-service/alert.service';
import {forkJoin} from 'rxjs';
import {noop} from '../../../helper/noop';
import {ShippingInstructionService} from '../../../envisia/article/services/shipping-instruction.service';
import {ProductionStepQuestionnaireComponent} from './production-step-questionnaire.component';

@Component({
  selector: 'production-step',
  templateUrl: './production-step.component.html',
})
export class ProductionStepComponent {

  @Input() objects: ProductionStepEntity[];

  constructor(private modalService: NgbModal,
              private productionStepService: ProductionStepService,
              private articleSpecificationService: ArticleSpecificationService,
              private shippingInstructionService: ShippingInstructionService,
              private alertService: AlertService) {
  }

  trackById(index: number, obj: ProductionStepEntity): number {
    noop(this);
    return obj.id;
  }

  newProductionStep() {
    const modalRef = this.modalService.open(ProductionStepCreateComponent, {windowClass: 'modal2-mm'});
    modalRef.result.then((value) => {
      this.objects.push(value);
    }, () => { // ignore empty backdrop
    });
  }

  toggle(index: number, obj: ProductionStepEntity) {
    this.productionStepService.toggle(obj.id).subscribe(value => {
      this.objects[index] = value;
    });
  }

  stepEdit(index: number, obj: ProductionStepEntity) {
    const modalRef = this.modalService.open(ProductionStepEditComponent, {windowClass: 'modal2-mm'});
    modalRef.componentInstance.data = obj;
    modalRef.result.then(value => {
      this.objects[index] = value;
    }, () => { // ignore empty backdrop
    });
  }

  editArticle(index: number, obj: ProductionStepEntity) {
    forkJoin([
      this.articleSpecificationService.description(),
      this.shippingInstructionService.schema(true)
    ]).subscribe(responses => {
      const modalRef = this.modalService.open(ProductionStepArticleComponent, {windowClass: 'modal2-full'});
      modalRef.componentInstance.object = obj;
      modalRef.componentInstance.articleSpecification = responses[0];
      // "Zieldatum" should not be shown - See Issue #1657
      modalRef.componentInstance.articleExtraValidation = responses[1].filter(si => si.label !== 'Zieldatum');
      modalRef.result.then(value => {
        this.objects[index] = value;
      }, () => {
      });
    }, () => {
      this.alertService.add(
        'danger',
        'Es ist ein Fehler aufgetreten, bitte benachrichtigen Sie Ihren Administrator!'
      );
    });
  }

  editQuestionnaire(productionStep: ProductionStepEntity): void {
    this.articleSpecificationService.description().subscribe(articleSpecs => {
      const modalRef = this.modalService.open(ProductionStepQuestionnaireComponent, {windowClass: 'modal2-xl'});
      modalRef.componentInstance.productionStep = productionStep;
      modalRef.componentInstance.articleSpecification = articleSpecs;
    }, () => {
      this.alertService.add(
        'danger',
        'Es ist ein Fehler aufgetreten, bitte benachrichtigen Sie Ihren Administrator!'
      );
    });
  }

}
