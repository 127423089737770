import {EnvisiaObjects} from '../../../common/wrapper.models';
import {environment} from '../../../../environments/environment';
import Axios, {AxiosResponse} from 'axios';
import {AxiosHttpClient} from '../../../envisia/auth/axios-http-client';
import {
  ProductionStepQuestionnaire,
  ProductionStepQuestionnaireUpsertForm,
} from './production-step-questionnaire.models';

export class ProductionStepQuestionnaireAxiosService {
  static upsert(productionStepId: number, form: ProductionStepQuestionnaireUpsertForm):
    Promise<ProductionStepQuestionnaire | null> {
    return AxiosHttpClient.post<ProductionStepQuestionnaire | null>(
      `${environment.apiv4uri}article/config/production/step/${productionStepId}/questionnaire`,
      form,
    );
  }

  static delete(productionStepId: number, id: number): Promise<any> {
    return AxiosHttpClient.delete(
      `${environment.apiv4uri}article/config/production/step/${productionStepId}/questionnaire/${id}`
    );
  }

  static list(
    productionStepId: number,
    abortController?: AbortController,
  ): Promise<AxiosResponse<EnvisiaObjects<ProductionStepQuestionnaire>>> {
    return Axios.create().get<EnvisiaObjects<ProductionStepQuestionnaire>>(
      `${environment.apiv4uri}article/config/production/step/${productionStepId}/questionnaire`,
      {signal: abortController?.signal},
    );
  }
}
