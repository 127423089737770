import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {MaterialWithCharge} from '../../../material/material-management.model';
import {BusinessOrderMaterialService} from '../services/business-order-material.service';
import {BusinessOrder} from '../models/business-order.models';
import {BusinessOrderStepView} from '../models/business-order-step.models';

@Component({
  selector: 'business-order-view-charge-box',
  templateUrl: './business-order-view-charge-box.component.html',
})
export class BusinessOrderViewChargeBoxComponent implements OnInit, OnChanges {
  @Input() ba: BusinessOrder;
  @Input() step: BusinessOrderStepView;
  loadingWrapper: LoadingWrapper<MaterialWithCharge[]>;

  constructor(private service: BusinessOrderMaterialService) {
  }

  ngOnInit(): void {
    this.listMaterialsWithCharge();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentStepOrdering &&
      !changes.currentStepOrdering.isFirstChange()) {
      this.listMaterialsWithCharge();
    }
  }

  listMaterialsWithCharge(): void {
    this.loadingWrapper = new LoadingWrapper<MaterialWithCharge[]>(
      this.service.getMaterialsWithCharge(this.ba.id, this.ba.index));
  }
}
