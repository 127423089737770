import * as React from 'react';
import {ArticleSpecification} from '../models/article-specification.model';
import {useCombobox, UseComboboxStateChange} from 'downshift';
import {clsx} from 'clsx';

interface Props {
  specification: ArticleSpecification[];
  inputClassName: string;
  value: string;
  nameChanged?: (name: string) => void;
  labelChanged?: (name: string) => void;
}

export function ArticleSpecificationSelectCombobox(props: Props) {
  const onStateChange: (changes: UseComboboxStateChange<ArticleSpecification>) => void = (changes) => {
    props.nameChanged(changes.selectedItem?.name);
    props.labelChanged(changes.selectedItem?.description);
  };

  function initial(): ArticleSpecification | null {
    return props.specification.find(articleSpec => articleSpec.name === props.value) ?? null;
  }

  function articleSpecToString(articleSpec?: ArticleSpecification): string | null {
    return !articleSpec ? null : `${articleSpec.name.trim()} (${articleSpec.description.trim()})`;
  }

  const {
    isOpen,
    inputValue,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox<ArticleSpecification>({
    items: [...props.specification],
    initialInputValue: initial()?.name ?? '',
    initialSelectedItem: initial(),
    onStateChange: onStateChange,
    itemToString: articleSpec => articleSpec?.name,
  });

  return <>
    <div className="inline-block" {...getMenuProps()}>
      <input className={props.inputClassName} {...getInputProps()} />
      {isOpen &&
        <div {...getMenuProps()} className="absolute bg-white p-2 z-10 shadow-md min-w-80 max-h-80 overflow-y-auto">
          {props.specification
            .map((articleSpec, index) => ({articleSpec: articleSpec, index: index}))
            .filter(element =>
              articleSpecToString(element.articleSpec)
                ?.toLocaleLowerCase('de-DE')
                ?.includes(inputValue.trim().toLocaleLowerCase('de-DE')))
            .map(element => (
              <div key={element.articleSpec.name}
                   {...getItemProps({key: element.articleSpec.name, index: element.index, item: element.articleSpec})}
                   className={`p-2 ${clsx({
                     'text-blue-500': highlightedIndex === element.index,
                     'font-bold': selectedItem?.name === element.articleSpec.name,
                   })}`}>
                {articleSpecToString(element.articleSpec)}
              </div>
            ))
          }
        </div>
      }
    </div>
  </>;
}
