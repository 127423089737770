import * as React from 'react';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {ReactBaseComponent} from '../../../react/base/react-base.component';
import {RightService} from '../../../common/right-service/right.service';
import {ArticleUsageStateStandaloneModal} from '../../../react/article/state/article-usage-state-standalone-modal';

const containerElementName = 'reactArticleUsageStateStandaloneModal';

@Component({
  selector: 'article-usage-state-standalone-modal',
  template: `<div class="inline" #${containerElementName}></div>`,
})
export class ArticleUsageStateStandaloneModalComponent extends ReactBaseComponent implements OnInit {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() oaNr: string;
  hasEditPermissions: boolean;

  constructor(private rightService: RightService) {
    super();
  }

  ngOnInit() {
    this.hasEditPermissions = this.rightService.has('article.change_state');
  }

  protected renderComponent(): JSX.Element {
    const _ = React.version;
    return <>
      <ArticleUsageStateStandaloneModal oaNr={this.oaNr}
                                        hasEditPermissions={this.hasEditPermissions} />
    </>;
  }
}
