import * as React from 'react';
import {ProductionStepEntity} from '../../../envisia/article/production/production.models';
import {clsx} from 'clsx';
import {
  ProductionStepQuestionnaire,
  ProductionStepQuestionnaireFormFull,
} from './production-step-questionnaire.models';
import {ArticleSpecification} from '../../../envisia/article/models/article-specification.model';
import {
  ArticleSpecificationSelectCombobox,
} from '../../../envisia/article/article-specification-autosuggest/article-specification-select-combobox';
import {ReactNode} from 'react';
import {UseFormReturn} from 'react-hook-form';

interface Props {
  productionStep: ProductionStepEntity;
  articleSpecification: ArticleSpecification[];
  questionnaire?: ProductionStepQuestionnaire;
  errors: { [key: string]: string };
  index: number;
  formType: 'updates' | 'creates';
  form: UseFormReturn<ProductionStepQuestionnaireFormFull>,
  children?: ReactNode | ReactNode[];
}

export function ProductionStepQuestionnaireFormView(props: Props): JSX.Element {
  const formPrefix: `${'updates' | 'creates'}.${number}.` = `${props.formType}.${props.index}.`;
  return <>
    <div className="grid grid-cols-[20%_15%_15%_15%_15%_10%_10%] grid-rows-1 gap-2">
      <div className="form-group">
        <input id="name"
               type="text"
               className={clsx({'form-control': true, 'red_alert': !!props.errors[`${formPrefix}name`]})}
               {...props.form.register(`${formPrefix}name`, {required: true})}/>
        {(props.errors[`${formPrefix}name`] === 'exists') && <span className="red_font">Existiert bereits</span>}
      </div>

      <div className="form-group">
        <ArticleSpecificationSelectCombobox
          inputClassName={clsx({
            'form-control': true,
            'red_alert': !!props.errors[`${formPrefix}article_field_name`],
          })}
          value={props.form.watch(`${formPrefix}article_field_name`)}
          specification={props.articleSpecification}
          labelChanged={v =>
            props.form.setValue(`${formPrefix}article_field_label`, v, {shouldDirty: true})}
          nameChanged={v =>
            props.form.setValue(`${formPrefix}article_field_name`, v, {shouldDirty: true})}/>
      </div>

      <div className="form-group">
        <input id="values_per_bo"
               type="number"
               className={clsx({'form-control': true, 'red_alert': !!props.errors[`${formPrefix}values_per_bo`]})}
               {...props.form.register(`${formPrefix}values_per_bo`, {required: true})}/>
      </div>

      <div className="form-group">
        <input id="repetitions"
               type="number"
               className={clsx({'form-control': true, 'red_alert': !!props.errors[`${formPrefix}repetitions`]})}
               {...props.form.register(`${formPrefix}repetitions`, {required: true})}/>
      </div>

      <div className="form-group">
        <input id="unit"
               type="text"
               className={clsx({'form-control': true, 'red_alert': !!props.errors[`${formPrefix}unit`]})}
               {...props.form.register(`${formPrefix}unit`, {required: false})}/>
      </div>

      <div>
        <button type="button"
                className="btn btn-default btn-sm btn-link"
                title={
                  'Abfrage von Prüffeld in BA Abschluss ' +
                  (props.form.watch(`${formPrefix}active`) ? 'deaktivieren' : 'aktivieren')
                }
                onClick={() =>
                  props.form.setValue(
                    `${formPrefix}active`,
                    !props.form.watch(`${formPrefix}active`),
                    {shouldDirty: true},
                  )}>
          <i className={clsx({
            'fa fa-2x fa-toggle-off text-red-600': !props.form.watch(`${formPrefix}active`),
            'fa fa-2x fa-toggle-on text-green-600': props.form.watch(`${formPrefix}active`),
          })}/>
        </button>
      </div>

      <div>
        {props.children}
      </div>
    </div>
  </>;
}
