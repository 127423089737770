import {EnvisiaObjects} from '../../../common/wrapper.models';
import {FieldArrayWithId, UseFormReturn} from 'react-hook-form';

export interface ProductionStepQuestionnaireForm {
  name: string;
  unit?: string;
  data_type: number;
  article_field_name?: string;
  article_field_label?: string;
  values_per_bo: number;
  repetitions: number;
  active: boolean;
}

export interface ProductionStepQuestionnaireFormWithId extends ProductionStepQuestionnaireForm {
  id: number;
}

export interface ProductionStepQuestionnaireUpsertForm {
  creates: ProductionStepQuestionnaireForm[];
  updates: ProductionStepQuestionnaireFormWithId[];
}

export interface ProductionStepQuestionnaire extends ProductionStepQuestionnaireFormWithId {
  production_step_id: number;
  date_deleted?: string;
}

export interface ProductionStepQuestionnaireFormElement {
  name: string;
  unit: string;
  data_type: number;
  article_field_name: string;
  article_field_label: string;
  values_per_bo: number;
  repetitions: number;
  active: boolean;
}

export interface ProductionStepQuestionnaireFormElementWithId extends ProductionStepQuestionnaireFormElement {
  questionnaire_id: number,
}

export interface ProductionStepQuestionnaireFormCreates {
  creates: ProductionStepQuestionnaireFormElement[],
}

export interface ProductionStepQuestionnaireFormUpdates {
  updates: ProductionStepQuestionnaireFormElementWithId[],
}

export interface ProductionStepQuestionnaireFormFull {
  creates: ProductionStepQuestionnaireFormElement[],
  updates: ProductionStepQuestionnaireFormElementWithId[],
}

export interface ProductionStepQuestionnaireSum {
  questionnaire_id: number;
  article_value: string;
  count: number;
}

export interface ProductionStepQuestionnaireListElement {
  questionnaire: ProductionStepQuestionnaire;
  sums: ProductionStepQuestionnaireSum[];
}

export interface ProductionStepQuestionnaireListState {
  list?: EnvisiaObjects<ProductionStepQuestionnaire>;
  isInteractionLocked: boolean;
  submitLast?: number;
  errors: { [key: string]: string };
}

export interface ProductionStepQuestionnaireListHook {
  state: ProductionStepQuestionnaireListState;
  listReload: () => void;
  addCreateRow: () => void;
  deleteCreateRow: (index: number) => void;
  submitUpsert: (form: ProductionStepQuestionnaireFormFull) => void;
  submitDelete: (questionnaire: ProductionStepQuestionnaire) => void;
  updateForm: UseFormReturn<ProductionStepQuestionnaireFormFull, any, undefined>;
  createFormArray: {
    form: FieldArrayWithId<ProductionStepQuestionnaireFormCreates, 'creates', 'id'>,
  }[];
  updateFormArray: {
    form: FieldArrayWithId<ProductionStepQuestionnaireFormUpdates, 'updates', 'id'>,
    element: ProductionStepQuestionnaire,
  }[];
}

export interface ProductionStepQuestionnaireDataType {
  id: number;
  idString: string;
  name: string;
  isNumber: boolean;
  decimals: number;
}

export class ProductionStepQuestionnaireDataTypes {
  private static dataTypesInternal: {[key: string]: ProductionStepQuestionnaireDataType} = {
    '0': {id: 0, idString: '0', name: 'Text', isNumber: false, decimals: 0},
    '2': {id: 2, idString: '2', name: 'Fließkommazahl', isNumber: true, decimals: 3},
    '3': {id: 3, idString: '3', name: 'Positive Fließkommazahl', isNumber: true, decimals: 3},
    '4': {id: 4, idString: '4', name: 'Ganzzahl', isNumber: true, decimals: 0},
    '5': {id: 5, idString: '5', name: 'Positive Ganzzahl', isNumber: true, decimals: 0},
    '128': {id: 128, idString: '128', name: 'Wahrheitswert', isNumber: false, decimals: 0},
  };

  static getType(id?: number | null | undefined): ProductionStepQuestionnaireDataType | null {
    if (!id && id !== 0) {
      return null;
    }

    return ProductionStepQuestionnaireDataTypes.dataTypesInternal[id.toString(10)] ?? null;
  }
}
