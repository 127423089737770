import * as React from 'react';
import {UsageStateType, UsageStateTypes} from './article-usage-state.model';
import {useSelect, UseSelectStateChange} from 'downshift';
import {clsx} from 'clsx';
import {EvLabel} from '../../components/labels/ev-label';
import {NumberHelper} from '../../../helper/number-helper';
import {useEffect, useState} from 'react';

interface Props {
  selected: UsageStateType | null;
  selectedChanged: (value: UsageStateType | null) => void;
}

const dropdownListElements: (UsageStateType | null)[] = [null, ...UsageStateTypes.list];

export function ArticleUsageStateDropdown(props: Props): JSX.Element {
  const [selected, setSelected] = useState<UsageStateType | null>(props.selected);
  const onStateChange: (changes: UseSelectStateChange<UsageStateType | null>) => void = (changes) => {
    if (changes.selectedItem === undefined) {
      return;
    }

    if (changes.selectedItem?.state !== selected?.state) {
      setSelected(changes.selectedItem);
      props.selectedChanged(changes.selectedItem ?? null);
    }
  };

  const {
    selectedItem,
    isOpen,
    highlightedIndex,
    getItemProps,
    getToggleButtonProps,
    getMenuProps,
  } = useSelect<UsageStateType>({
    items: dropdownListElements,
    selectedItem: selected,
    defaultSelectedItem: null,
    initialSelectedItem: selected,
    onStateChange: onStateChange,
  });

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return <>
    <div className="w-36 flex flex-col gap-1">
      <div className="form-control
                      input-sm
                      flex
                      justify-between
                      cursor-pointer
                      text-center"
           {...getToggleButtonProps()}>
        {
          !selectedItem ?
            <span>-</span> :
            <EvLabel className="pt-1.5" labeltype={selectedItem.className}>
              {selectedItem.stateName}
            </EvLabel>
        }
        <span className="px-2">{isOpen ? <>&#8593;</> : <>&#8595;</>}</span>
      </div>
    </div>
    <ul {...getMenuProps()}
        className={clsx({
          'absolute w-72 bg-white mt-1 shadow-md max-h-80 overflow-y-auto overflow-x-clip p-0 z-10': true,
          'hidden': !isOpen,
        })}>
      {isOpen && dropdownListElements.map((item, index) => (
        <li key={index}
            className={clsx({
              'py-2 px-3 shadow-sm flex flex-col': true,
              'bg-blue-300': highlightedIndex === index,
              'font-bold': selectedItem === item,
            })}
            {...getItemProps({item, index})}>
          {item === null ?
            <span className="w-full text-center">-</span> :
            <EvLabel className="pt-1.5" labeltype={item.className}>
              {item.stateName}
            </EvLabel>
          }
        </li>
      ))
      }
    </ul>
  </>;
}
