import {Component, ErrorHandler, Input, OnInit} from '@angular/core';
import {SearchBaseComponent} from '../../../../common/search/search.component';
import {BusinessOrderService} from '../services/business-order.service';
import {BusinessOrderListElem} from '../models/business-order.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {Lister} from '../../../../common/wrapper.models';
import {noop} from '../../../../helper/noop';
import {MaterialAggregation} from '../../../material/material-management.model';
import {DateRange} from '../../../../core/date-range/date-range.models';
import {DateHelper} from '../../../../helper/date-helper';
import {ArticleSpecification} from '../../../article/models/article-specification.model';

@Component({
  selector: 'business-order-list',
  templateUrl: './business-order-list.component.html',
  styleUrls: ['./business-order-list.component.scss'],
})
export class BusinessOrderListComponent
  extends SearchBaseComponent<BusinessOrderService, BusinessOrderListElem>
  implements OnInit {
  @Input() materialValues: string[] = [];
  errors: { [key: string]: any; } = {};
  @Input() protected listData: Lister<BusinessOrderListElem>;
  @Input() filteredMaterial?: MaterialAggregation;
  @Input() specification: { [key: string]: ArticleSpecification };
  protected type = 'business_order';

  constructor(protected service: BusinessOrderService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  trackByFn(index: number, item: BusinessOrderListElem): number {
    noop(this);
    return item.id * 100 + item.index;
  }

  unsetVariation(): void {
    this.filteredMaterial = null;
    const query = this.query();
    query.variation_id = undefined;
    delete query['variation_id'];
    this.data['variation_id'] = null;
    query.page = 1;
    this.serviceCall(query);
  }

  getMatVals() {
    return this.materialValues;
  }

  selectStandard(): void {
    this.statusList.add('target_material');
    this.data = {status: '0', target_material: 'standard*'};
    this.query({status: '0', target_material: 'standard*'});
    this.search();
  }

  selectNoMapping(): void {
    this.statusList.add('target_material');
    this.data = {status: '0', target_material: 'no_mapping*'};
    this.query({status: '0', target_material: 'no_mapping*'});
    this.search();
  }

  updateDateRangeStart(date: DateRange): void {
    this.data['range_start_start'] = DateHelper.format(date.start);
    this.data['range_start_end'] = DateHelper.format(date.end);
    this.search();
  }

  updateDateRangeEnd(date: DateRange): void {
    this.data['range_end_start'] = DateHelper.format(date.start);
    this.data['range_end_end'] = DateHelper.format(date.end);
    this.search();
  }

  filterToggle(type: string): void {
    const query = this.query();
    query[type] = !!query[type] ? null : true;
    query.page = null;
    this.serviceCall(query);
  }

  get hasEmpbFilter() {
    const q = this.query();
    return q.has_empb === 'true' || q.has_empb === true;
  }

  get hasKanBanFilter() {
    const q = this.query();
    return q.has_kanban === 'true' || q.has_kanban === true;
  }

  get hasPluggingFilter() {
    const q = this.query();
    return q.has_plugging === 'true' || q.has_plugging === true;
  }

  get hasCopperFillingFilter() {
    const q = this.query();
    return q.has_copper_filling === 'true' || q.has_copper_filling === true;
  }

  get hasBlindHoleFilter() {
    const q = this.query();
    return q.has_blind_hole === 'true' || q.has_blind_hole === true;
  }

  get isType3() {
    const q = this.query();
    return q.is_type3 === 'true' || q.is_type3 === true;
  }

  get isType6() {
    const q = this.query();
    return q.is_type6 === 'true' || q.is_type6 === true;
  }
}
