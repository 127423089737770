<div class="modal-header">
  <h3 class="modal-title">
    Lieferschein für Menge {{ quantityRequired }} Stück
  </h3>
</div>

<form [formGroup]="form">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <table class="whitetable table">
          <thead>
          <tr>
            <th>Datecode</th>
            <th>Max. Lagerzeit</th>
            <th>Vorhandene Menge</th>
            <th>Lager</th>
            <th>Lagerplatz</th>
            <th>Vorgang</th>
            <th>Verwendete Menge</th>
            <th></th>
          </tr>
          </thead>
          <tbody formArrayName="inventories">
          <tr [ngClass]="{
                'warning': inventory.element.max_date_reached,
                'success': inventory.element.workflow_id === workflow?.id
              }"
              [formGroup]="inventory.form"
              *ngFor="let inventory of inventoryFormArrayMapped; trackBy: trackByFnInventories">
            <td>{{ inventory.element.date_code }}</td>
            <td>{{ inventory.element.date_code_max }}</td>
            <td>{{ inventory.element.quantity }}</td>
            <td>{{ inventory.element.storage_bin_name }}</td>
            <td>{{ inventory.element.storage_bin_number }}</td>
            <td>{{ inventory.element.workflow_id }}</td>
            <td>
              <integer-input class="input-bg text-right w-full"
                             formControlName="quantity"
                             [evClass]="{red_alert: errors['inventory.' + inventory.index + '.quantity']}"
                             [autofocus]="inventory.index === 0">
              </integer-input>
            </td>
            <td>Stück</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td class="text-right" colspan="6">Verbleidende Menge:</td>
            <td class="text-right"><strong class="pr-1">{{quantityLeft}}</strong></td>
            <td>Stück</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="row margin-up-down-10">
      <div class="col-md-12">
        <label for="note"><strong>Lagerbuchungs Notiz</strong></label>
        <textarea class="form-control" rows="5" id="note" formControlName="note">
      </textarea>
      </div>
    </div>

    <table class="whitetable table max-h-[90dvh] scrollbar-thin scroll-smooth overflow-y-auto"
           formArrayName="labels">
      <thead>
      <tr>
        <th class="w-1/3">
          Versand Etikett
        </th>
        <th class="w-1/3">
          Gewicht
        </th>
        <th class="w-1/3" colspan="3">
          <button type="button" class="btn btn-info w-full" (click)="addLabel()">Etikett hinzufügen</button>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr [formGroup]="labelForm"
          *ngFor="let labelForm of labelFormArrayMapped; trackBy: trackByFnLabels">
        <td>Etikett {{ labelForm.value.index }}</td>
        <td colspan="2">
          <integer-input class="input-bg w-full text-right"
                         formControlName="weight"
                         [ngClass]="{red_alert: errors['labels.' + labelForm.value.index + '.weight']}">
          </integer-input>
        </td>
        <td class="w-2">kg</td>
        <td class="w-2">
          <button class="btn btn-link btn-default btn-sm"
                  title="Etikett löschen"
                  (click)="removeLabel(labelForm)">
            <i class="fa fa-trash-o text-red-500"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">

    <button class="btn btn-default pull-left" type="button" (click)="dismiss()">
      Abbrechen
    </button>

    <loading-button clazz="btn btn-success pull-right"
                    name="Lieferschein erstellen"
                    (saveEmitter)="submit($event)"
                    [disabled]="quantityLeft !== 0 || !form.valid">
    </loading-button>
  </div>

</form>
