import * as React from 'react';
import {ArticleMultilayerPlanPrepregElementModel} from '../article-multilayer-plan.models';
import {useEffect, useState} from 'react';
import {ArticleMultilayerPlanConstants} from '../article-multilayer-plan-constants';

interface Props {
  index: number;
  value: string;
  changeModel: (prepreg: ArticleMultilayerPlanPrepregElementModel) => void;
}

export function ArticleMultilayerPlanMaterialSelect(props: Props) {
  /* Field parsing */

  const [currentValue, currentValueSet] = useState<string | null>(props.value ?? null);

  const getThickness: (tissue: string) => number = (tissue) => {
    return ArticleMultilayerPlanConstants.mapPrepregDataToThickness(tissue);
  };

  /* Update functionality */
  const changePrepreg: (value: string | null) => void = (value) => {
    if (currentValue === value) {
      return;
    }

    if (!!ArticleMultilayerPlanConstants.prepregTissues.find(v => v === value)) {
      currentValueSet(value);
      props.changeModel({tissue: value, thickness: getThickness(value)});
    } else {
      currentValueSet(null);
      props.changeModel({tissue: null, thickness: null});
    }
  };

  const renderOptions = () => {
    return ArticleMultilayerPlanConstants.prepregTissues.map(m => <option key={m} value={m}>{m}</option>);
  };

  useEffect(() => {
    changePrepreg(props.value);
  }, [props.value]);

  return <>
    <select className={'form-control'}
            style={{display: 'inline-block', width: '245px', padding: '2px', textAlign: 'right'}}
            value={currentValue ?? ''}
            onChange={t => changePrepreg(t.target.value)}>
      <option value={''}>-</option>
      {renderOptions()}
    </select>
  </>;
}
