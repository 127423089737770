export interface ArticleUsageStateStandaloneProps {
  oaNr: string;
  hasEditPermissions: boolean;
}

export interface ArticleUsageStateProps extends ArticleUsageStateStandaloneProps {
  usageState: number;
  usageStateChanged?: (usageState: number) => void;
}

export interface ArticleUsageStateForm {
  usage_state: number;
}

export interface ArticleUsageModalState {
  usageState: UsageStateType;
  modalState: 'closed' | 'open';
  isInteractionLocked: boolean;
}

export interface ArticleUsageModalHook {
  state: ArticleUsageModalState;
  setModalState: (state: 'closed' | 'open') => void;
  setUsageState: (state: number) => void;
}

export interface UsageStateType {
  state: number;
  stateString: string;
  stateName: string;
  className: string;
}

export class UsageStateTypes {
  static types: {[key: `${number}`]: UsageStateType} = {
    '0': {state: 0, stateString: '0', stateName: 'Bestand', className: 'default'},
    '1': {state: 1, stateString: '1', stateName: 'Neu', className: 'warning'},
    '2': {state: 2, stateString: '2', stateName: 'DE auf CN', className: 'danger'},
    '3': {state: 3, stateString: '3', stateName: 'Änderung', className: 'info'},
  }

  static list: UsageStateType[] = Object.keys(UsageStateTypes.types).map(k => UsageStateTypes.types[k]);

  static getType(usageState?: number): UsageStateType {
    return UsageStateTypes.types[(usageState ?? 0).toString(10)];
  }
}
