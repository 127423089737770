import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {UIRouterModule} from '@uirouter/angular';
import {WorkflowListComponent} from '../../../envisia/workflow/main/workflow-list.component';
import {WorkflowNavigationModule} from '../navigation/workflow-navigation.module';
import {WorkflowPipesModule} from '../pipes/workflow-pipes.module';
import {WORKFLOW_MAIN_STATES} from './workflow-main.states';
import {WorkflowCreateArticleSearchModule} from './workflow-create-article-search.module';
import {WorkflowCreateComponent} from './workflow-create.component';
import {CreateTaskModule} from '../create-task/create-task.module';
import {WorkflowCreateCustomerComponent} from './workflow-create-customer.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ArticleUsageStateModule} from '../../article/article-usage-state/article-usage-state.module';

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({states: WORKFLOW_MAIN_STATES}),
        WorkflowPipesModule,
        WorkflowNavigationModule,
        WorkflowCreateArticleSearchModule,
        CreateTaskModule,
        NgbTooltipModule,
        ArticleUsageStateModule,
    ],
    declarations: [
        WorkflowListComponent,
        WorkflowCreateComponent,
        WorkflowCreateCustomerComponent,
    ],
    exports: []
})
export class WorkflowMainModule {
}
