import {Component, Input, OnInit} from '@angular/core';
import {BusinessOrder, BusinessOrderReproduction, BusinessOrderView} from '../models/business-order.models';
import {BusinessOrderStepView} from '../models/business-order-step.models';
import {FetchPositionWithFetchAndFrame} from '../../models/fetch.models';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {BusinessOrderGoodpieces} from './goodpiece-box/business-order-view-goodpiece-box.models';
import {BusinessOrderViewGoodpieceService} from './goodpiece-box/business-order-view-goodpiece.service';
import {of} from 'rxjs';
import {BusinessOrderService} from '../services/business-order.service';
import {Comment} from '../../../comment/comment.models';

@Component({
  selector: 'business-order-view',
  templateUrl: './business-order-view.component.html',
  styles: [
    '.label-spacer {margin-left: 100px;}',
    '.ba-label-row {margin-top: 5px; font-size: 18px !important;}',
  ],
})
export class BusinessOrderViewComponent implements OnInit {
  @Input() data: BusinessOrderView;
  @Input() fetches: FetchPositionWithFetchAndFrame[];
  goodpieces: LoadingWrapper<BusinessOrderGoodpieces>;
  ba: BusinessOrder;
  reproduction?: BusinessOrderReproduction;
  steps: BusinessOrderStepView[];
  step701: BusinessOrderStepView;
  comments: Comment[];
  item: string;
  workflowWarning: boolean;
  has_plugging: boolean;
  has_copper_filling: boolean;
  showMaterialsWithCharge: boolean = false;

  constructor(private service: BusinessOrderService,
              private goodpieceService: BusinessOrderViewGoodpieceService) {
  }

  ngOnInit(): void {
    this.setData(this.data);
  }

  setData(data: BusinessOrderView): void {
    this.workflowWarning = data.workflow_status >= 100;
    this.ba = data.object;
    this.steps = data.steps;
    this.reproduction = data.reproduction;
    this.item = this.ba.id + '-' + this.ba.index;
    this.has_plugging = data.article.data.via_plugging || data.article.data.plugging_core;
    this.has_copper_filling = data.article.data.cu_filling || data.article.data.cu_filling_core;
    this.comments = data.comments;
    this.updateShowMaterialsWithCharge();
  }

  updateShowMaterialsWithCharge(): void {
    this.step701 = this.steps?.find(s => s.nr === 701);
    this.showMaterialsWithCharge = (this.data.article.data.solder_resist_a === 'Grün' || this.data.article.data.solder_resist_b === 'Grün') &&
      this.step701 &&
      this.ba.current_step_ordering !== null &&
      this.step701.ordering <= this.ba.current_step_ordering;
  }

  updateGoodPiece(): void {
    this.goodpieces = new LoadingWrapper(this.goodpieceService.get(this.ba.id, this.ba.index));
  }

  setGoodPiece(goodpieces: BusinessOrderGoodpieces): void {
    this.goodpieces = new LoadingWrapper(of(goodpieces));
  }

  updateReproduction(reproduction: BusinessOrderReproduction): void {
    this.reproduction = reproduction;
  }

  updateData(): void {
    this.service.detail(this.ba.id, this.ba.index).subscribe(ba => {
      this.setData(ba);
    });
  }

}
