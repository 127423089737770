import {Ng2StateDeclaration, Transition, StateService} from '@uirouter/angular';
import {WorkflowNavigationCreateComponent} from '../navigation/workflow-navigation-create.component';
import {WorkflowListComponent} from './workflow-list.component';
import {WorkflowService} from '../workflow-services/workflow.service';
import {WorkflowCreateDocumentService} from '../workflow-services/workflow-create-document.service';
import {WorkflowDetailService} from '../workflow-services/workflow-detail.service';
import {WorkflowFactoryService} from '../workflow-services/workflow-factory.service';
import {ErpTransition} from '../../../core/erp-transition.service';
import {WorkflowCreateComponent} from './workflow-create.component';

export const WORKFLOW_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.new',
  url: '/workflow/create',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: WorkflowCreateComponent}
  }
};

export const WORKFLOW_MAIN_C_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.c',
  abstract: true,
  data: {requiresAuth: true}
};

export function workflowListResolveFn(transition: ErpTransition, service: WorkflowService) {
  return service.list(transition.params()).toPromise();
}

export const WORKFLOW_MAIN_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.c.list',
  url: '/workflow/process/list?page&order&sort&status&date_start&date_end&typ&approval&kanban&sid&quanity&oa_nr&sds_nr&kd_art_nr&customer_name&customer_field_service&username&has_release&additional_order_nr&article_usage_state',
  views: {
    'navigation@a': {component: WorkflowNavigationCreateComponent},
    'main@a': {component: WorkflowListComponent}
  },
  resolve: [{token: 'listData', resolveFn: workflowListResolveFn, deps: [ErpTransition, WorkflowService]}],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    status: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    typ: {dynamic: true},
    approval: {dynamic: true},
    kanban: {dynamic: true},
    sid: {dynamic: true},
    quanity: {dynamic: true},
    oa_nr: {dynamic: true},
    sds_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    customer_name: {dynamic: true},
    customer_field_service: {dynamic: true},
    username: {dynamic: true},
    has_release: {dynamic: true},
    additional_order_nr: {dynamic: true},
    article_usage_state: {dynamic: true},
  },
  data: {requiresAuth: true}
};

export function workflowMainDetailStateResolveFn(service: WorkflowDetailService, transition: Transition) {
  return service.call(transition.params().id).toPromise();
}

export function workflowMainDetailStateRedirectFn(transition: Transition) {
  const workflowId = transition.params().id;
  const stateService = transition.injector().get<StateService>(StateService);
  const resolvePromise = transition.injector().getAsync('data');
  return resolvePromise.then(resolveData => {
    let targetState;
    if (WorkflowFactoryService.workflowStatusCheck(resolveData.workflow.object)) {
      targetState = stateService.target(
        'a.workflow.detail.detail_offer',
        {data: resolveData, id: workflowId}
      );
    } else {
      targetState = stateService.target(
        'a.workflow.detail.detail_contract',
        {data: resolveData, id: workflowId}
      );
    }

    return targetState;
  }, () => {
    return 'o.error404';
  });
}

export const WORKFLOW_MAIN_STATE: Ng2StateDeclaration = {
  name: 'a.workflow',
  abstract: true,
  data: {requiresAuth: true}
};

export const WORKFLOW_MAIN_DETAIL_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.detail',
  url: '/workflow/{id}',
  resolve: [{token: 'data', resolveFn: workflowMainDetailStateResolveFn, deps: [WorkflowDetailService, Transition]}],
  redirectTo: workflowMainDetailStateRedirectFn,
  data: {requiresAuth: true}
};


export function workflowDocumentCreateResolveFn(service: WorkflowCreateDocumentService, transition: Transition) {
  return service.call(transition).toPromise();
}

export function workflowDocumentCreateRedirectToFn(transition: Transition) {
  const params = transition.params();
  const stateService = transition.injector().get<StateService>(StateService);
  const resolvePromise = transition.injector().getAsync('data');
  return resolvePromise.then(resolveData => {
    let targetState;
    const isOffer = WorkflowFactoryService.workflowStatusCheck(resolveData.workflow.object);

    if (params.name === 'offer' && isOffer) {
      targetState = stateService.target('a.workflow.create.create_offer_create',
        {data: resolveData, id: params.id, name: params.name});
    } else if (params.name === 'contract' && isOffer) {
      targetState = stateService.target('a.workflow.create.create_offer_create_contract',
        {data: resolveData, id: params.id, name: params.name});
    } else if (params.name === 'contract' && !isOffer) {
      targetState = stateService.target('a.workflow.create.create_contract_create',
        {data: resolveData, id: params.id, name: params.name});
    } else if (params.name === 'delivery' && !isOffer) {
      targetState = stateService.target('a.workflow.create.create_delivery_create',
        {data: resolveData, id: params.id, name: params.name});
    } else if (params.name === 'invoice' && !isOffer) {
      targetState = stateService.target('a.workflow.create.create_invoice_create',
        {data: resolveData, id: params.id, name: params.name});
    } else {
      targetState = 'o.error404';
    }

    return targetState;
  }, () => {
    return 'o.error404';
  });
}

export const WORKFLOW_DOCUMENT_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.workflow.create',
  url: '/workflow/create/{name}/{id}',
  resolve: [
    {token: 'data', resolveFn: workflowDocumentCreateResolveFn, deps: [WorkflowCreateDocumentService, Transition]}
  ],
  redirectTo: workflowDocumentCreateRedirectToFn,
  data: {requiresAuth: true}
};

export const WORKFLOW_MAIN_STATES: Ng2StateDeclaration[] = [
  WORKFLOW_MAIN_STATE,
  WORKFLOW_MAIN_DETAIL_STATE,
  WORKFLOW_CREATE_STATE,
  WORKFLOW_MAIN_C_STATE,
  WORKFLOW_MAIN_LIST_STATE,
  WORKFLOW_DOCUMENT_CREATE_STATE
];
