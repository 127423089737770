<td>
  <a uiSref="a.business_order.view" [uiParams]="{id:ba.id, index:ba.index}">
    {{ ba.id }}-{{ ba.index }} / {{ ba.count }}
  </a>
  <div>
    <business-order-label [surface]="ba.surface"
                          [empb]="!!ba.empb && !!ba.first_contract"
                          [kanban]="ba.kanban"
                          [hasPlugging]="ba.has_plugging"
                          [hasCopperFilling]="ba.has_copper_filling"
                          [hasReproduction]="!!ba.reproduction"
                          [hasBlindHole]="ba.has_blind_hole"
                          [isT3]="ba.is_t3"
                          [isT6]="ba.is_t6">
    </business-order-label>
  </div>
  <ev-file-button *ngIf="ba.status !== 0"
                  [url]="'/api/v1/workflow/business/order/pdf/' + ba.id + '/' + ba.index+ '/'"
                  [link]="true">
    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
  </ev-file-button>
</td>
<td>
  <span *ngIf="!ba.is_express">{{ ba.work_days }} AT</span>
  <span *ngIf="ba.is_express" class="label label-purple marginless">{{ ba.work_days }} AT</span>
</td>
<td>
  <workflow-state [key]="ba.status" type="business_order_status"
                  [additional]="ba.status === 1 ? ba.percent + '%' : null"></workflow-state>
</td>
<td>
  {{ ba.current_step }}
  <span *ngIf="ba.step_duration" class="label" [ngClass]="ba.step_duration_label">{{ ba.step_duration }}</span>
</td>
<td>
  <a uiSref="a.business_order.create" [uiParams]="{id: ba.workflow_id}">
    {{ ba.workflow_id }}
  </a>
</td>
<td>{{ ba.start_date }}</td>
<td>
  {{ ba.end_date }} / {{ ba.end_week }}
</td>
<td class="align-right">
  {{ ba.quantity|money }}
</td>
<td class="align-right">
  {{ ba.quantity_ba|money }}
</td>
<td class="align-right">
  ({{ba.utilize_quantity|money}})
</td>
<td class="align-right">{{ ba.good_piece|money }}</td>
<td style="min-width: 60px;">{{ba.lp_type ?? '-'}}</td>
<td style="min-width: 60px;">{{ba.hdi_type ?? '-'}}</td>
<td [ngSwitch]="materialStatus">
  <div *ngSwitchCase="'button'">
    <button type="button"
            class="btn btn-sm"
            [disabled]="!('ba.material.edit'|HasPerm)"
            [ngClass]="materialButtonStatus"
            (click)="openMaterialSelectModal()">
      {{ ba.material_planning_article_names.length > 0 ? ba.material_planning_article_names[0] : 'Zuordnungsfehler' }}
    </button>
    <div style="padding: 10px 0 0;" *ngIf="!!ba.material_customer_specification">
      <div class="label label-danger red_alert" style="margin: 0;">
        <i class="fa fa-exclamation-triangle"></i>&nbsp;Materialvorgabe
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'selected'">
    {{ ba.material_planning_article_names.length > 0 ? ba.material_planning_article_names[0] : 'Standard FR4' }}
  </div>

  <div *ngSwitchCase="'booked'">
    {{ ba.applied_material }}
  </div>

  <div *ngSwitchCase="'china'">
    CN Artikel
  </div>

  <div *ngSwitchDefault>
    Standard FR4
  </div>
</td>
<td><a uiSref="a.article.workflow.detail" [uiParams]="{id:ba.oa_nr}">{{ ba.sds_nr }}</a></td>
<td>{{ ba.kd_art_nr }}</td>
<td>
  <a uiSref="a.crm.customer.detail" [uiParams]="{ id: ba.customer_id }">
    {{ ba.customer_id }} - {{ ba.customer }}
  </a>
</td>
<td [title]="ba.date_created_with_time + ' - ' + ba.created_username">{{ ba.date_created }}</td>
<td>
  <button type="button"
          class="btn btn-sm btn-danger"
          (click)="storno()"
          *ngIf="ba.status <= 1 && ('business_order.storno'|HasPerm)">
    Stornieren
  </button>
</td>
<td>
  <button type="button"
          class="btn btn-sm btn-success"
          (click)="startOrder()"
          *ngIf="ba.status === 0 && ('ba.start'|HasPerm)">
    Start
  </button>
</td>
