<div class="filter-row">
  <div class="filter-left">
    <search-type typ="de" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>
    <search-type typ="cn" [current]="currentSearchType()" (update)="updateSearchType($event)"></search-type>

    <button class="btn btn-default btn-sm" type="button"
            (click)="badge('true', 'not_send', true)"
            [ngClass]="{active: q('true', 'not_send')}">
      Nicht übermittelt <span class="badge"></span>
    </button>

    <button class="btn btn-default btn-sm" type="button"
            (click)="badge('true', 'storno', true)"
            [ngClass]="{active: q('true', 'storno')}">
      Storniert
    </button>

    <button class="btn btn-default btn-sm" type="button"
            (click)="badge('all')"
            [ngClass]="{active: q('all')}">
      Alle
    </button>

    <ev-file-button *ngIf="('invoice.export'|HasPerm)"
                    title="Rechnungs- Liste CSV"
                    [url]="downloadUrl('/workflow/v1/invoice/export/excel/')"
                    [link]="false">
      <i class="fa fa-download"></i>
    </ev-file-button>

    <ev-file-button *ngIf="('invoice.export'|HasPerm)"
                    title="Rechnungspositionen- Liste Excel"
                    [url]="excelPositionDownload"
                    [disabled]="!!data.storno"
                    [link]="false">
      <i class="fa fa-file-excel-o"></i>
    </ev-file-button>

    <ev-file-button title="Steuerbüro CSV"
                    [url]="downloadUrl('/workflow/v1/invoice/export/csv/')"
                    [link]="false"
                    *ngIf="('invoice.export'|HasPerm)">
      Steuerbüro
    </ev-file-button>
  </div>

  <div class="filter-right">
    <date-range [start]="null" [end]="null" (update)="updateDateRange($event)"></date-range>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'id', 'DESC')" [href]="sortUrl('workflow_id', 'DESC')">
          Rechnung
        </a>
      </th>
      <th>&nbsp;</th>
      <th>
        <a (click)="sort($event, 'workflow_id', 'ASC')" [href]="sortUrl('workflow_id', 'ASC')">
          Vorgang
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_dunning_level', 'ASC')" [href]="sortUrl('invoice_dunning_level', 'ASC')">
          Mahnstufe
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'date', 'ASC')" [href]="sortUrl('date', 'ASC')">
          Datum
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'invoice_quantity', 'ASC')" [href]="sortUrl('invoice_quantity', 'ASC')">
          Stückzahl
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'sds_nr', 'ASC')" [href]="sortUrl('sds_nr', 'ASC')">
          SDS-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'oa_nr', 'ASC')" [href]="sortUrl('oa_nr', 'ASC')">
          0A-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'kd_art_nr', 'ASC')" [href]="sortUrl('kd_art_nr', 'ASC')">
          Kd-Art-Nr.
        </a>
      </th>
      <th style="width: 100px;">
        <a (click)="sort($event, 'customer_id', 'ASC')" [href]="sortUrl('customer_id', 'ASC')">
          Kunden-Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_name', 'ASC')" [href]="sortUrl('customer_name', 'ASC')">
          Kunde
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_zip', 'ASC')" [href]="sortUrl('customer_zip', 'ASC')">
          PLZ
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_city', 'ASC')" [href]="sortUrl('customer_city', 'ASC')">
          Stadt
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_country', 'ASC')" [href]="sortUrl('customer_country', 'ASC')">
          Land
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'customer_field_service', 'ASC')" [href]="sortUrl('customer_field_service', 'ASC')">
          Vertriebsgebiet
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'username', 'ASC')" [href]="sortUrl('username', 'ASC')">
          Ersteller
        </a>
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    <tr class="filterrow">
      <th>
        <input type="text"
               class="form-control input-sm"
               name="sid"
               [autofocus]
               [ngClass]="{ 'red_alert': !!filterFormErrors.sid }"
               [(ngModel)]="data.sid"
               autocomplete="off search-invoice-id">
      </th>
      <th>&nbsp;</th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="workflow_id"
               [ngClass]="{ 'red_alert': !!filterFormErrors.workflow_id }"
               [(ngModel)]="data.workflow_id">
      </th>
      <th>
        <select class="form-control input-sm"
                name="invoice_dunning_level"
                [(ngModel)]="data.invoice_dunning_level"
                [ngClass]="{ 'red_alert': !!filterFormErrors.invoice_dunning_level }">
          <option [value]="''">- Mahnstufe -</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </th>
      <th>&nbsp;</th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="invoice_quantity"
               [ngClass]="{ 'red_alert': !!filterFormErrors.invoice_quantity }"
               [(ngModel)]="data.invoice_quantity">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="sds_nr"
               [ngClass]="{ 'red_alert': !!filterFormErrors.sds_nr }"
               [(ngModel)]="data.sds_nr">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="oa_nr"
               [ngClass]="{ 'red_alert': !!filterFormErrors.oa_nr }"
               [(ngModel)]="data.oa_nr">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="kd_art_nr"
               [ngClass]="{ 'red_alert': !!filterFormErrors.oa_nr }"
               [(ngModel)]="data.kd_art_nr">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_id"
               [ngClass]="{ 'red_alert': !!filterFormErrors.oa_nr }"
               [(ngModel)]="data.customer_id">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_name"
               [ngClass]="{ 'red_alert': !!filterFormErrors.customer_name }"
               [(ngModel)]="data.customer_name">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_zip"
               [ngClass]="{ 'red_alert': !!filterFormErrors.customer_zip }"
               [(ngModel)]="data.customer_zip">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_city"
               [ngClass]="{ 'red_alert': !!filterFormErrors.customer_city }"
               [(ngModel)]="data.customer_city">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_country"
               [ngClass]="{ 'red_alert': !!filterFormErrors.customer_country }"
               [(ngModel)]="data.customer_country">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="customer_field_service"
               [ngClass]="{ 'red_alert': !!filterFormErrors.customer_field_service }"
               [(ngModel)]="data.customer_field_service">
      </th>
      <th>
        <input type="text"
               class="form-control input-sm"
               name="username"
               [ngClass]="{ 'red_alert': !!filterFormErrors.username }"
               [(ngModel)]="data.username">
      </th>
      <th colspan="3">&nbsp;</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let object of list.objects; let index = index" ng-class="{'alert-danger': object.frame.status}"
        class="chrissies">
      <td class="width-200">
        <ev-file-button [objId]="object.id" type="invoice">
          <span ng-class="{deleted: object.invoice_storno_id}">{{ object.id }}</span>
          <span class="label" [ngClass]="'label-' + object.invoice_state.label">
            {{ object.invoice_state.name }}
          </span>
        </ev-file-button>

        <ev-file-button [objId]="object.invoice_storno_id" type="invoice_correction" *ngIf="object.invoice_storno_id">
          <span class="label label-warning">
            Storno
          </span>
        </ev-file-button>
      </td>
      <td>
        <img *ngIf="object.typ === 'cn'" src="/ui/assets/images/flags/cn.png">
        <img *ngIf="object.typ === 'de'" src="/ui/assets/images/flags/de.png">
      </td>
      <td>
        <a uiSref="a.workflow.detail" [uiParams]="{id: object.workflow_id}">
          {{ object.workflow_id }}
          <span *ngIf="object.comments > 0" class="td-comments" title="Kommentar(e) vorhanden">
            <i class="fa fa-comments"></i>
          </span>
        </a>
      </td>
      <td>{{ object.invoice_dunning_level }}</td>
      <td>{{ object.invoice_date }}</td>
      <td>{{ object.invoice_quantity }}</td>
      <td><strong>{{ object.sds_nr }}</strong></td>
      <td>{{ object.oa_nr }}</td>
      <td>
        {{ object.kd_art_nr }}
        <span *ngIf="object.kd_art_index" class="label label-default">{{ object.kd_art_index }}</span>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}">{{ object.customer_id }}</a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}">{{ object.customer_name }}</a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}">{{ object.customer_zip }}</a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}">{{ object.customer_city }}</a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}">{{ object.customer_country }}</a>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:object.customer_id}">{{object.customer_field_service}}</a>
      </td>
      <td><a href="mailto:{{ object.email }}">{{ object.username }}</a></td>
      <td>
        <!-- Crossinx -->
        <div class="label"
             [ngClass]="{'label-success': object.invoice_state.id > 1, 'label-warning': object.invoice_state.id === 1}"
             *ngIf="object.invoice_state.id > 0"
             placement="top" [ngbTooltip]="object.invoice_date_sent">
          <ng-container *ngIf="object.invoice_state.id === 1">wird Übermittelt</ng-container>
          <ng-container *ngIf="object.invoice_state.id > 1">Übermittelt</ng-container>
        </div>

        <button type="button" class="label label-default label-button"
                *ngIf="object.invoice_state.id === 0"
                (click)="invoiceModal(object)">
          Rechnung übermitteln
        </button>
      </td>
      <td>
        <a class="btn btn-default btn-sm" uiSref="a.price.check" [uiParams]="{id: object.oa_nr}"
           *ngIf="'price.detail'|HasPerm">
          Prüfung
        </a>
      </td>
      <td>
        <button type="button" class="btn btn-sm btn-link" (click)="history(object)">
          <i class="fa fa-history"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
