import {useEffect, useRef, useState} from 'react';
import {
  ProductionStepQuestionnaire,
  ProductionStepQuestionnaireFormFull,
  ProductionStepQuestionnaireListHook,
  ProductionStepQuestionnaireListState,
  ProductionStepQuestionnaireFormElement,
  ProductionStepQuestionnaireFormElementWithId,
  ProductionStepQuestionnaireForm,
  ProductionStepQuestionnaireFormWithId,
} from './production-step-questionnaire.models';
import {ProductionStepQuestionnaireAxiosService} from './production-step-questionnaire-axios.service';
import Axios from 'axios';
import {useFieldArray, useForm} from 'react-hook-form';
import {NumberHelper} from '../../../helper/number-helper';

function mapForm(formField: ProductionStepQuestionnaireFormElement): ProductionStepQuestionnaireForm {
  return {
    name: formField.name,
    unit: !formField.unit ? null : formField.unit,
    data_type: NumberHelper.saveParseInteger(formField.data_type),
    article_field_name: !formField.article_field_name ? null : formField.article_field_name,
    article_field_label: !formField.article_field_label ? null : formField.article_field_label,
    values_per_bo: NumberHelper.saveParseInteger(formField.values_per_bo),
    repetitions: NumberHelper.saveParseInteger(formField.repetitions),
    active: formField.active ?? false,
  };
}

function mapFormWithId(formField: ProductionStepQuestionnaireFormElementWithId): ProductionStepQuestionnaireFormWithId {
  return {...mapForm(formField), id: formField.questionnaire_id} as ProductionStepQuestionnaireFormWithId;
}

interface Props {
  productionStepId: number;
}

export function useProductionStepQuestionnaire(props: Props): ProductionStepQuestionnaireListHook {
  const abortController = useRef<AbortController | null>(null);
  const [state, setState] = useState<ProductionStepQuestionnaireListState>({
    list: null,
    isInteractionLocked: false,
    submitLast: null,
    errors: {},
  });

  const form = useForm({
    defaultValues: {
      'creates': [] as ProductionStepQuestionnaireFormElement[],
      'updates': [] as ProductionStepQuestionnaireFormElementWithId[],
    },
  });

  const createArray = useFieldArray({
    control: form.control,
    name: 'creates',
  });

  const updateArray = useFieldArray({
    control: form.control,
    name: 'updates',
  });

  const listReload: () => void = async () => {
    abortController.current?.abort();
    try {
      abortController.current = new AbortController();
      const list = await ProductionStepQuestionnaireAxiosService.list(props.productionStepId, abortController.current);
      setState({...state, list: list.data, errors: {}});

      // Clear form arrays
      createArray.remove(createArray.fields.map((_, i) => i));
      updateArray.remove(updateArray.fields.map((_, i) => i));
      list.data.objects.forEach(q => updateArray.append({
        'questionnaire_id': q.id,
        'name': q.name,
        'unit': q.unit,
        'data_type': q.data_type,
        'article_field_name': q.article_field_name,
        'article_field_label': q.article_field_label,
        'values_per_bo': q.values_per_bo,
        'repetitions': q.repetitions,
        'active': q.active,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const addCreateRow: () => void = () => {
    createArray.append({
      'name': '',
      'unit': '',
      'data_type': 2,
      'article_field_name': null,
      'article_field_label': null,
      'values_per_bo': 1,
      'repetitions': 3,
      'active': true,
    });
  };

  const deleteCreateRow: (index: number) => void = (index) => {
    createArray.remove(index);
  };

  const submitUpsert: (
    form: ProductionStepQuestionnaireFormFull,
  ) => void = async (form) => {
    setState({...state, errors: {}, isInteractionLocked: true});
    try {
      await ProductionStepQuestionnaireAxiosService.upsert(
        props.productionStepId,
        {
          creates: form.creates.map(mapForm),
          updates: form.updates.map(mapFormWithId),
        });
      listReload();
    } catch (error) {
      console.error(error);
      if (Axios.isAxiosError(error) && (error.response?.status === 409)) {
        console.log(error.response.data);
        const existing = (error.response?.data?.existing as string[]).map(n => n.trim().toLowerCase());
        const errors: { [key: string]: string } = {};
        const names =
          [
            ...form.creates.map((cf, i) =>
              ({name: cf.name.trim().toLowerCase(), path: `creates.${i}.name`})),
            ...form.updates.map((cf, i) =>
              ({name: cf.name.trim().toLowerCase(), path: `updates.${i}.name`})),
          ];
        if (!!existing && existing.length > 0) {
          names.filter(f => !!existing.find(name => f.name === name))
            .forEach(f => {
              errors[f.path] = 'exists';
            });
        }

        setState({...state, errors: errors, isInteractionLocked: false});
      } else if (Axios.isAxiosError(error)) {
        setState({...state, errors: error.response?.data?.errors ?? {}, isInteractionLocked: false});
      }
    }
  };

  const submitDelete: (
    questionnaire: ProductionStepQuestionnaire,
  ) => void = async (questionnaire) => {
    if (!confirm('Soll das Prüffeld wirklich gelöscht werden?')) {
      return;
    }

    setState({...state, errors: {}, isInteractionLocked: true});
    try {
      await ProductionStepQuestionnaireAxiosService.delete(questionnaire.production_step_id, questionnaire.id);
      listReload();
    } catch (error) {
      console.error(error);
      if (Axios.isAxiosError(error)) {
        setState({...state, errors: error.response?.data?.errors ?? {}, isInteractionLocked: false});
      }
    }
  };

  useEffect(() => {
    listReload();
  }, [props.productionStepId]);

  return {
    state: state,
    listReload: listReload,
    addCreateRow: addCreateRow,
    deleteCreateRow: deleteCreateRow,
    submitUpsert: submitUpsert,
    submitDelete: submitDelete,
    updateForm: form,
    createFormArray: createArray.fields.map(f => ({
      form: f,
    })),
    updateFormArray: updateArray.fields.map(f => ({
      form: f,
      element: state.list?.objects?.find(q => q.id === f.questionnaire_id),
    })),
  };
}
