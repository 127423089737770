import Axios, {AxiosResponse} from 'axios';
import {environment} from '../../../../environments/environment';
import {ArticleMeasurementLister} from './article-measurement-analysis.model';
import {AxiosHttpClient} from '../../../envisia/auth/axios-http-client';

export class ArticleMeasurementAnalysisService {
  static count(oaNr: string, questionnaireId: number): Promise<void> {
    return AxiosHttpClient.get(
      `${environment.apiv4uri}article/${oaNr}/measurement/${questionnaireId}`
    );
  }

  static delete(oaNr: string, questionnaireId: number, questionnaireResultId: number): Promise<void> {
    return AxiosHttpClient.delete(
      `${environment.apiv4uri}article/${oaNr}/measurement/${questionnaireId}/${questionnaireResultId}`
    );
  }

  static list(
    oaNr: string,
    questionnaireId: number,
    abortController?: AbortController,
  ): Promise<AxiosResponse<ArticleMeasurementLister>> {
    return Axios.create().get<ArticleMeasurementLister>(
      `${environment.apiv4uri}article/${oaNr}/measurement/${questionnaireId}`,
      {signal: abortController?.signal},
    );
  }

  static listAll(
    oaNr: string,
    abortController?: AbortController,
  ): Promise<AxiosResponse<ArticleMeasurementLister[]>> {
    return Axios.create().get<ArticleMeasurementLister[]>(
      `${environment.apiv4uri}article/${oaNr}/measurements`,
      {signal: abortController?.signal},
    );
  }

  static listQuestionnaires(
    boId: number,
    boIndex: number,
    productionStepId: number,
    abortController?: AbortController,
  ): Promise<AxiosResponse<ArticleMeasurementLister>> {
    return Axios.create().get<ArticleMeasurementLister>(
      `${environment.apiv4uri}business/order/${boId}/${boIndex}/step/questionnaires/${productionStepId}`,
      {signal: abortController?.signal},
    );
  }

}
