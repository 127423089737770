import * as React from 'react';
import {ReactNode} from 'react';
import {EvFormattedNumber} from '../../components/ev-formatted-number';

interface Props {
  label: string;
  unit?: string;
  value?: string | number;
  children: ReactNode | ReactNode[];
  decimals?: number;
}

export function ArticleMeasurementAnalysisField(props: Props): JSX.Element {
  return <>

    <div className="article-form-item">
      <div className="article-form-item-label">{props.label}:</div>

      <div className="article-form-item-padding"/>

      <div className="article-form-item-data">
        <div className="article-form-data-content">
          <div className="block
                          h-[30px]
                          pt-[4px] pr-[12px] pb-[6px] pl-[12px]
                          border border-solid border-[#CCC] rounded-[4px]
                          text-[14px] text-[#555] bg-white leading-[1.428571429]
                          shadow-[inset_0_1px_1px_rgba(0,0,0,0.075)]
                          transition-border-color transition-box-shadow ease-in-out delay-150">
            {((typeof props.value) === 'number') ?
              <EvFormattedNumber value={props.value as number} decimals={(props.decimals > 0 ? props.decimals : 0)}/> :
              props.value
            }
          </div>
        </div>
        {
          !!props.unit &&
          <div className="article-form-data-content">
            <div className="block
                            h-[30px]
                            pt-[8px] pr-[6px] pb-[10px] pl-[6px]
                            -ml-1
                            bg-[#EEE]
                            text-[#555]
                            text-left
                            text-[10px]
                            border border-solid border-[#CCC] rounded-tr-[4px] rounded-br-[4px]">
              {props.unit}
            </div>
          </div>
        }

        {props.children}
      </div>
    </div>
  </>;
}
