import * as React from 'react';
import {EvReactModal} from '../../modal/EvReactModal';
import {EvDate} from '../../components/ev-date';
import {EvConfirm} from '../../modal/EvConfirm';
import {ArticleMeasurementAnalysisHook} from './article-measurement-analysis.model';
import {ArticleMeasurementAnalysisField} from './article-measurement-analysis-field';
import {EvFormattedNumber} from '../../components/ev-formatted-number';

export function ArticleMeasurementAnalysis(
  {
    state,
    setModalState,
    setModalStateConfirmDelete,
    deleteReading,
  }: ArticleMeasurementAnalysisHook): JSX.Element {
  return <>
    <ArticleMeasurementAnalysisField label={state.list.questionnaire.name}
                                     unit={state.list.questionnaire.unit}
                                     value={state.selectedSum?.questionnaire_value_averaged}
                                     decimals={state.dataType?.decimals ?? 0}>
      <button type="button"
              title="Auswertungshistorie anzeigen"
              className="btn btn-default btn-link btn-sm cursor-pointer inline"
              onClick={() => setModalState('list')}>
        <i className="fa fa-clock-o text-blue-500"/>
      </button>
    </ArticleMeasurementAnalysisField>

    <EvReactModal contentLabel={`Auswertungshistorie - ${state.list.questionnaire.name}`}
                  showModal={state.modalState !== 'closed'}
                  modalClosed={() => setModalState('closed')}>
      {state.modalState === 'confirm-delete' &&
        <EvConfirm response={a => a ? deleteReading() : setModalState('list')}
                   responseLocked={state.isInteractionLocked}>
          <span>
            Soll die Messung für den BA
          </span>
          <strong className="ml-1">
            <a href={`#!/workflow/business/order/${state.selected.bo_id}/${state.selected.bo_index}`}
               title="BA Öffnen"
               target="_blank">
              {state.selected.bo_id}-{state.selected.bo_index}
            </a>
          </strong>
          <span className="ml-1">
            Wert Nr.&nbsp;{state.selected.index + 1}
          </span>
          <span className="ml-1">
            vom <EvDate isoDateTime={state.selected.date_created}/> wirklich gelöscht werden?
          </span>
        </EvConfirm>
      }

      {state.modalState === 'list' &&
        <table className="table whitetable">
          <thead>
          <tr className="filterrow">
            <td>BA Nr.</td>
            <td className="text-right">Nr.</td>
            <td>Artikelwert</td>
            <td>Wert</td>
            <td>Einheit</td>
            <td>Nutzer</td>
            <td>Erstelldatum</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {state.list.objects.map(qr => {
            return <tr key={qr.id}>
              <td>
                <strong>
                  <a href={`#!/workflow/business/order/${qr.bo_id}/${qr.bo_index}`}
                     title="BA Öffnen"
                     target="_blank">
                    {qr.bo_id}-{qr.bo_index}
                  </a>
                </strong>
              </td>
              <td className="text-right">{qr.index + 1}</td>
              <td>{qr.article_value}</td>
              {
                (state.dataType?.isNumber === true) ?
                  <td>
                    <EvFormattedNumber value={qr.questionnaire_value as number}
                                       decimals={state.dataType?.decimals ?? 0}/>
                  </td> :
                  <td>
                    {qr.questionnaire_value}
                  </td>
              }
              <td>{state.list.questionnaire.unit}</td>
              <td>{qr.user_name}</td>
              <td><EvDate isoDateTime={qr.date_created}/></td>
              <td>
                <button type="button"
                        className="btn btn-default btn-sm btn-link cursor-pointer"
                        onClick={() => setModalStateConfirmDelete(qr)}>
                  <i className="fa fa-trash-o text-red-600"/>
                </button>
              </td>
            </tr>;
          })}
          </tbody>
        </table>
      }
    </EvReactModal>
  </>;
}
