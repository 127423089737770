import * as React from 'react';
import {ArticleMeasurementLister} from './article-measurement-analysis.model';
import {useState} from 'react';
import {ArticleMeasurementAnalysisField} from './article-measurement-analysis-field';
import {clsx} from 'clsx';
import {ArticleMeasurementAnalysisWrapper} from './article-measurement-analysis-wrapper';

interface Props {
  oaNr: string;
  label: string;
  questionnaireIds: number[];
  measurementLists: ArticleMeasurementLister[];
  listUpdated?: (list: ArticleMeasurementLister) => void;
}

export function ArticleMeasurementAnalysisSum(props: Props): JSX.Element {
  const filteredMeasurements = () =>
    props.measurementLists.filter(ml => !!props.questionnaireIds.find(id => ml.questionnaire.id === id));
  const getSum: () => number = () => {
    return filteredMeasurements()
      .map(ml => ml.sums.find(s => s.article_value === '')?.questionnaire_value_averaged ?? 0)
      .reduceRight((agg, ml) => agg + ml);
  };

  const [showAllState, setShowAllState] = useState<boolean>(false);

  return <>
    {
      showAllState && <>
        {filteredMeasurements().map(ml =>
          <ArticleMeasurementAnalysisWrapper key={ml.questionnaire.id}
                                             oaNr={props.oaNr}
                                             questionnaireId={ml.questionnaire.id}
                                             list={ml}
                                             listUpdated={props.listUpdated}/>
        )}
      </>
    }
    <ArticleMeasurementAnalysisField label={props.label} value={getSum()} unit="Min / Fe.Nu.">
      <button type="button"
              title="Alle anzeigen"
              className="btn btn-default btn-link btn-sm cursor-pointer inline"
              onClick={() => setShowAllState(!showAllState)}>
        <i className={clsx({
          'fa': true,
          'fa-minus text-red-500': showAllState,
          'fa-plus text-green-500': !showAllState,
        })}/>
      </button>
    </ArticleMeasurementAnalysisField>
  </>;
}
