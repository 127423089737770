import * as React from 'react';
import {
  Component,
  ElementRef,
  forwardRef,
  NgZone,
  ViewChild,
} from '@angular/core';
import {ReactBaseComponent} from '../../../react/base/react-base.component';
import {ArticleUsageStateDropdown} from '../../../react/article/state/article-usage-state-dropdown';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UsageStateType, UsageStateTypes} from '../../../react/article/state/article-usage-state.model';

const containerElementName = 'reactArticleUsageStateModal';

export const EV_ARTICLE_USAGE_STATE_DROPDOWN_INPUT = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => ArticleUsageStateDropdownComponent),
  multi: true,
};

@Component({
  selector: 'article-usage-state-dropdown',
  template: `<div #${containerElementName}></div>`,
  providers: [EV_ARTICLE_USAGE_STATE_DROPDOWN_INPUT],
})
export class ArticleUsageStateDropdownComponent extends ReactBaseComponent implements ControlValueAccessor {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  selected: UsageStateType | null = null;

  // @formatter:off
  private _onChange = (_: any) => {};
  // @formatter:on

  constructor(private ngZone: NgZone) {
    super();
  }

  writeValue(value: any): void {
    const parsed = UsageStateTypes.types[value] ?? null;
    if (parsed?.state === this.selected?.state) {
      return;
    }

    this.selected = parsed;
    this.render();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  updated(usageState: UsageStateType): void {
    this.ngZone.run(() => {
      this.selected = usageState;
      this._onChange(this.selected?.stateString);
    });
  }

  protected renderComponent(): JSX.Element {
    const _ = React.version;
    return <ArticleUsageStateDropdown selected={this.selected}
                                      selectedChanged={value => this.updated(value)}/>;
  }
}
