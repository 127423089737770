import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {ArticleUsageStateDropdownComponent} from './article-usage-state-dropdown.component';
import {ArticleUsageStateModalComponent} from './article-usage-state.component';
import {ArticleUsageStateStandaloneModalComponent} from './article-usage-state-standalone-modal.component';
@NgModule({
  imports: [
    SharedModule,
  ],
  exports: [
    ArticleUsageStateModalComponent,
    ArticleUsageStateDropdownComponent,
    ArticleUsageStateStandaloneModalComponent,
  ],
  declarations: [
    ArticleUsageStateModalComponent,
    ArticleUsageStateDropdownComponent,
    ArticleUsageStateStandaloneModalComponent,
  ],
})
export class ArticleUsageStateModule {
}
