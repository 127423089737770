import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusinessOrder, BusinessOrderReproduction} from '../../models/business-order.models';
import {BusinessOrderStepView} from '../../models/business-order-step.models';
import {BusinessOrderStepService} from '../../services/business-order-step.service';
import {ArticleSpecificationService} from '../../../../article/services/article-specification.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderStepStopComponent} from '../../step/business-order-step-stop.component';
import {BusinessOrderViewMergeService} from '../merge/business-order-view-merge.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BusinessOrderViewMergeModalService} from '../merge/business-order-view-merge-modal.service';
import {StateService} from '@uirouter/angular';
import {forkJoin, from} from 'rxjs';
import {
  BusinessOrderWastrelReasonAxiosService,
} from '../../../../../lazy/config/business-order-wastrel/business-order-wastrel-reason-axios.service';
import {map} from 'rxjs/operators';
import {
  ArticleMeasurementAnalysisService,
} from '../../../../../react/article/analysis/article-measurement-analysis.service';

@Component({
  selector: 'business-order-view-step-list-element',
  templateUrl: './business-order-view-step-list-element.component.html',
})
export class BusinessOrderViewStepListElementComponent implements OnInit {
  @Input() ba: BusinessOrder;
  @Input() step: BusinessOrderStepView;
  @Input() stepPrevious?: BusinessOrderStepView;
  @Input() stepNext?: BusinessOrderStepView;
  @Input() reproduction?: BusinessOrderReproduction;
  @Input() filmInventory?: number;
  @Output() updateGoodPiece = new EventEmitter<void>();
  @Output() updateData = new EventEmitter<void>();
  @Output() updateReproduction = new EventEmitter<BusinessOrderReproduction>();

  toolTipText: string;
  private locked = false;

  constructor(private businessOrderStepService: BusinessOrderStepService,
              private articleSpecificationService: ArticleSpecificationService,
              private businessOrderMergeService: BusinessOrderViewMergeService,
              private mergeModal: BusinessOrderViewMergeModalService,
              private stateService: StateService,
              private alertService: AlertService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    const id = `${this.step.nr} - ${this.step.text}`;
    this.toolTipText = `Den Schritt "${id}" als Punkt für das Zusammenfügen der Nachfertigung mit dem Haupt BA wählen`;
  }

  get mergeButtonsVisible(): boolean {
    return (
      !!this.reproduction &&
      (this.ba.status < 100) &&
      ((this.step.state < 3) || this.reproduction.step_ordering === this.step.ordering)
    );
  }

  get startStopButtonsVisible(): boolean {
    return (
      !this.step.lock_text &&
      (this.ba.status === 1) &&
      (!this.stepPrevious || (this.stepPrevious.ordering !== this.reproduction?.step_ordering))
    );
  }

  headerClass(step: BusinessOrderStepView): string {
    if (step.state === 3) {
      return 'whitelist-item-default';
    } else if (step.lock_text !== undefined && step.lock_text !== null) {
      return 'whitelist-item-danger';
    } else if (step.state === 1) {
      return 'whitelist-item-info';
    } else if (step.state === 2) {
      return 'whitelist-item-success';
    } else {
      return 'whitelist-item-default';
    }
  }

  startStep(): void {
    if (!this.locked && confirm('Möchten Sie den Schritt ' + this.step.nr + ' - ' + this.step.text + ' anmelden?')) {
      this.locked = true;
      this.businessOrderStepService.startStep(this.step).subscribe(response => {
        this.alertService.add('success', 'Schritt ' + this.step.nr + ' - ' + this.step.text + ' erfolgreich angemeldet');
        this.step = response;
        this.locked = false;
        this.updateData.next();
      }, (error: HttpErrorResponse) => {
        switch (error.error) {
          case 'ba.step.not.current.step':
            this.alertService.add('danger', 'Der Schritt kann zur Zeit nicht gestartet werden!');
            break;
          case 'ba.step.locked':
            this.alertService.add('danger', 'Der Schritt ist zur Zeit gesperrt!');
            break;
          case 'ba.merge.required':
            this.alertService.add('danger', 'Die Nachfertigung muss mit dem Haupt BA zusammengeführt werden!');
            break;
          default:
            this.alertService.add('danger', 'Fehler beim Anmelden des Schrittes!');
            break;
        }
        this.locked = false;
      });
    }
  }

  stopStep(): void {
    forkJoin({
      manufacturer: this.articleSpecificationService.manufacturerList(),
      wastrelReasons: from(BusinessOrderWastrelReasonAxiosService.list()).pipe(map(r => r.data)),
      questionnaire: from(ArticleMeasurementAnalysisService.listQuestionnaires(
        this.ba.id,
        this.ba.index,
        this.step.production_step_id,
      )).pipe(map(r => r.data)),
    })
    .subscribe(lists => {
      const modalRef = this.modalService.open(BusinessOrderStepStopComponent);
      modalRef.componentInstance.bo = this.ba;
      modalRef.componentInstance.step = this.step;
      modalRef.componentInstance.stepPrevious = this.stepPrevious;
      modalRef.componentInstance.manufacturer = lists.manufacturer;
      modalRef.componentInstance.reasons = lists.wastrelReasons;
      modalRef.componentInstance.questionnaire = lists.questionnaire;
      modalRef.result.then(() => {
        this.updateData.next();
        this.updateGoodPiece.next();
      }, () => {
      });
    }, () => {
      this.alertService.add('danger', 'Fehler beim Beenden des Schrittes!');
    });
  }

  selectMergeStep(): void {
    const id = `${this.step.nr} - ${this.step.text}`;
    if (confirm(`Möchten Sie wirklich den Schritt "${id}" als Zusammenfügungspunkt für die Nachfertigung wählen?`)) {
      this.businessOrderMergeService
        .selectStep(this.reproduction.id, this.reproduction.index, this.step.ordering)
        .subscribe(result => {
          this.updateReproduction.emit(result.reproduction);
          this.updateData.next();
        }, (error: HttpErrorResponse) => {
          if (error.status === 409 && error.error === 'ba.step.next.started') {
            this.alertService.add('danger', 'Der nächste Schritt wurde bereits gestartet');
          }
        });
    }
  }

  removeMergeStep(): void {
    if (confirm('Möchten Sie wirklich den Zusammenfügungspunkt der Nachfertigung entfernen?')) {
      this.businessOrderMergeService
        .removeStep(this.reproduction.id, this.reproduction.index)
        .subscribe(result => {
          this.updateReproduction.emit(result.reproduction);
          this.updateData.next();
        }, (error: HttpErrorResponse) => {
          if (error.status === 409 && error.error === 'ba.step.not.set') {
            this.alertService.add('danger', 'Schritt wurde bereits entfernt');
            this.updateReproduction.emit({...this.reproduction, step_ordering: null, step_name: null});
          }
        });
    }
  }

  openMergeModal(): void {
    this.mergeModal.open(this.ba, this.reproduction).subscribe(() => {
      this.stateService.go(this.stateService.$current, this.stateService.params, {reload: true});
    });
  }
}
