import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {forkJoin, from, Observable, throwError} from 'rxjs';
import {DeliveryService} from '../../workflow-services/delivery.service';
import {Workflow} from '../../models/workflow.models';
import {DeliveryCreateModalData} from './delivery-create-modal.models';
import {catchError, mergeMap} from 'rxjs/operators';
import {DeliveryCreateModalFormInventoryComponent} from './delivery-create-modal-form-inventory.component';

@Injectable({
  providedIn: 'root'
})
export class DeliveryCreateModalService {

  constructor(private modalService: NgbModal,
              private deliveryService: DeliveryService) {
  }

  open(workflow: Workflow, data: DeliveryCreateModalData): Observable<any> {
    return forkJoin({
      fetches: this.deliveryService.fetches(workflow.id, {fetch: data.fetch}, data.nr),
      storage: this.deliveryService.listStorage(workflow.id, data.nr)
    }).pipe(
      catchError(error => {
        console.log('Error in Opening Delivery Create Modal', error);
        return throwError(error);
      }),
      mergeMap(val => {
        const modalRef = this.modalService.open(DeliveryCreateModalFormInventoryComponent, {windowClass: 'modal2-lg'});
        modalRef.componentInstance.data = data;
        modalRef.componentInstance.fetches = val.fetches;
        modalRef.componentInstance.storage = val.storage;
        modalRef.componentInstance.workflow = workflow;
        return from(modalRef.result);
      })
    );
  }
}
