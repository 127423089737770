import {
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import * as React from 'react';
import {ReactBaseComponent} from '../../../react/base/react-base.component';
import {ProductionStepEntity} from '../../../envisia/article/production/production.models';
import {ProductionStepQuestionnaireModalRoot} from './production-step-questionnaire-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ArticleSpecification} from '../../../envisia/article/models/article-specification.model';

const containerElementName = 'reactProductionStepQuestionnaire';

@Component({
  selector: 'production-step-questionnaire',
  template: `<div #${containerElementName}></div>`,
})
export class ProductionStepQuestionnaireComponent extends ReactBaseComponent {
  @Input() productionStep: ProductionStepEntity;
  @Input() articleSpecification: ArticleSpecification[];
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;

  constructor(private modalRef: NgbActiveModal) {
    super();
  }

  protected override renderComponent() {
    const _ = React.version;
    return <ProductionStepQuestionnaireModalRoot productionStep={this.productionStep}
                                                 articleSpecification={this.articleSpecification}
                                                 cancel={() => this.modalRef.close()}/>;
  }
}
