<table class="table whitetable business-order-view-table business-order-create"
       *ngIf="loadingWrapper.data$|async as articleList; else loadingOrError.template">
  <thead>
  <tr>
    <th colspan="4">Vermutlich verwendetes Material</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td><strong>Fertigungsschritt</strong></td>
    <td><strong>Artikel</strong></td>
    <td><strong>Charge</strong></td>
  </tr>
  <tr *ngIf="articleList.length === 0">
    <td colspan="4">Es wurden keine Chargen gefunden</td>
  </tr>
  <tr *ngFor="let article of articleList">
    <td><strong>{{ this.step.nr }}</strong>&nbsp;{{ this.step.text }}</td>
    <td>
        <a uiSref="a.material.inventory.update" target="_blank" [uiParams]="{variation: article.id}">
        <strong>{{ article.variation_id }}</strong>&nbsp;{{ article.article_name }}
        </a>
    </td>
    <td>
        <a uiSref="a.material.inventory.update" target="_blank" [uiParams]="{variation: article.id}">
        {{ article.charge }}
        </a>
    </td>
  </tr>
  </tbody>
</table>

<loading-or-error
  #loadingOrError
  [loadingWrapper]="loadingWrapper"
  [errorMessage]="'Konnte keine Chargen für die Materialien laden'">
</loading-or-error>
