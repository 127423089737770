import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {ArticleListComponent} from './article-list.component';
import {UIRouterModule} from '@uirouter/angular';
import {InventoryModalModule} from '../inventory/inventory-modal.module';
import {ArticleListSpecFilterComponent} from './article-list-spec-filter.component';
import {ArticleUsageStateModule} from '../article-usage-state/article-usage-state.module';

@NgModule({
  imports: [
    UIRouterModule.forChild(),
    SharedModule,
    InventoryModalModule,
    ArticleUsageStateModule,
  ],
  exports: [
    ArticleListSpecFilterComponent,
  ],
  declarations: [
    ArticleListComponent,
    ArticleListSpecFilterComponent,
  ]
})
export class ArticleListModule {
}
