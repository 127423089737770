import * as React from 'react';
import {ProductionStepEntity} from '../../../envisia/article/production/production.models';
import {useProductionStepQuestionnaire} from './production-step-questionnaire-state-hook';
import {EvPageLoadingSpinner} from '../../../react/components/ev-page-loading-spinner';
import {ArticleSpecification} from '../../../envisia/article/models/article-specification.model';
import {ProductionStepQuestionnaireFormView} from './production-step-questionnaire-form-view';

interface Props {
  productionStep: ProductionStepEntity;
  articleSpecification: ArticleSpecification[];
  cancel: () => void;
}

export function ProductionStepQuestionnaireModalRoot(props: Props): JSX.Element {
  const state = useProductionStepQuestionnaire({productionStepId: props.productionStep.id});
  if (!state.state.list?.objects) {
    return <EvPageLoadingSpinner/>;
  }

  return <>
    <form onSubmit={state.updateForm.handleSubmit(state.submitUpsert)}>
      <div className='modal-header'>
        <h3>Produktionsschritt {props.productionStep.nr} - {props.productionStep.text}</h3>
      </div>
      <div className='modal-body'>
        <div className="grid grid-cols-[20%_15%_15%_15%_15%_10%_10%] grid-rows-1 gap-2">
          <div>Bezeichnung</div>
          <div>Artikelfeld</div>
          <div>Anz. Werte</div>
          <div>Wiederholungen</div>
          <div>Einheit</div>
          <div>Aktiv</div>
          <div></div>
        </div>
        {
          state.updateFormArray.map((f, i) =>
            <ProductionStepQuestionnaireFormView key={f.form.id}
                                                 productionStep={props.productionStep}
                                                 articleSpecification={props.articleSpecification}
                                                 questionnaire={f.element}
                                                 index={i}
                                                 formType="updates"
                                                 form={state.updateForm}
                                                 errors={state.state.errors}>
              {
                !!props.productionStep &&
                <button type="button"
                        title="Prüffeld löschen"
                        className="btn btn-default btn-sm btn-link"
                        disabled={state.state.isInteractionLocked}
                        onClick={() => state.submitDelete(f.element)}>
                  <i className="fa fa-2x fa-trash-o text-red-600"/>
                </button>
              }
            </ProductionStepQuestionnaireFormView>
          )
        }

        <div className="grid grid-cols-[20%_15%_15%_15%_15%_10%_10%] grid-rows-1 gap-2">
          <div>
            {(state.createFormArray.length > 0) && <h3>Neue Prüffelder</h3>}
          </div>
          <div className="col-span-5"/>
          <div>
            <button type="button"
                    title="Neues Prüffeld hinzufügen"
                    className="btn btn-default btn-link pt-0 pb-0"
                    disabled={state.state.isInteractionLocked}
                    onClick={() => state.addCreateRow()}>
              <i className="fa fa-plus text-green-500"/>
            </button>
          </div>
        </div>

        {
          state.createFormArray.map((f, i) =>
            <ProductionStepQuestionnaireFormView key={f.form.id}
                                                 productionStep={props.productionStep}
                                                 articleSpecification={props.articleSpecification}
                                                 index={i}
                                                 formType="creates"
                                                 form={state.updateForm}
                                                 errors={state.state.errors}>
              {
                !!props.productionStep &&
                <button type="button"
                        title="neues Prüffeld entfernen"
                        className="btn btn-default btn-sm btn-link"
                        disabled={state.state.isInteractionLocked}
                        onClick={() => state.deleteCreateRow(i)}>
                  <i className="fa fa-2x fa-trash-o text-red-600"/>
                </button>
              }
            </ProductionStepQuestionnaireFormView>
          )
        }
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger pull-left" onClick={props.cancel}>
          Abbrechen
        </button>
        <button type="submit" className="btn btn-success pull-right" disabled={state.state.isInteractionLocked}>
          Speichern
        </button>
      </div>
    </form>
  </>;
}
