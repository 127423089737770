import {Transition, Ng2StateDeclaration} from '@uirouter/angular';
import {BusinessOrderCreateComponent} from './create/business-order-create.component';
import {businessOrderCreateResolve} from './create/business-order-create.resolver';
import {BusinessOrderListComponent} from './list/business-order-list.component';
import {BusinessOrderService} from './services/business-order.service';
import {BusinessOrderViewComponent} from './view/business-order-view.component';
import {ArticleSpecificationService} from '../../article/services/article-specification.service';
import {ErpTransition} from '../../../core/erp-transition.service';
import {MaterialManagementService} from '../../material/material-management.service';
import {EMPTY} from 'rxjs';
import {getArticleSpecificationResolveFn} from '../../article/article.states';

export const BUSINESS_ORDER_STATE: Ng2StateDeclaration = {
  name: 'a.business_order',
  abstract: true,
  data: {requiresAuth: true}
};

export const BUSINESS_ORDER_CREATE_STATE: Ng2StateDeclaration = {
  name: 'a.business_order.create',
  url: '/workflow/business/order/create/{id:[0-9]+}',
  views: {'main@a': {component: BusinessOrderCreateComponent}},
  resolve: businessOrderCreateResolve,
  data: {requiresAuth: true}
};

export function baListResolveFn(transition: ErpTransition, service: BusinessOrderService) {
  return service.list(transition.params()).toPromise();
}

export function materialValuesResolveFn(service: ArticleSpecificationService) {
  return service.manufacturerList().toPromise();
}

export function materialVariationResolveFn(ts: Transition, mms: MaterialManagementService) {
  const idString = ts.params().variation_id;
  const variation = idString ? Number.parseInt(idString, 10) : null;
  if (!!idString && Number.isInteger(variation)) {
    return mms.getAggregation(variation).toPromise();
  } else {
    return EMPTY;
  }
}

export const BUSINESS_ORDER_LIST_STATE: Ng2StateDeclaration = {
  name: 'a.business_order.list',
  url: '/workflow/business/order/?page&status&order&sort&date_start&date_end&sid&workflow_id&current_step&start_date&end_date&quantity&good_piece&{target_material:any}&oa_nr&sds_nr&kd_art_nr&customer&urgency&date_created&e_test&variation_id&range_start_start&range_start_end&range_end_start&range_end_end&lp_type&hdi_type&is_reproduction&has_empb&has_kanban&has_plugging&has_copper_filling&has_reproduction&has_blind_hole&is_type3&is_type6',
  views: {'main@a': {component: BusinessOrderListComponent}},
  resolve: [
    {token: 'listData', resolveFn: baListResolveFn, deps: [ErpTransition, BusinessOrderService]},
    {token: 'materialValues', resolveFn: materialValuesResolveFn, deps: [ArticleSpecificationService]},
    {token: 'filteredMaterial', resolveFn: materialVariationResolveFn, deps: [Transition, MaterialManagementService]},
    {token: 'specification', resolveFn: getArticleSpecificationResolveFn, deps: [ArticleSpecificationService]},
  ],
  params: {
    page: {dynamic: true},
    order: {dynamic: true},
    sort: {dynamic: true},
    date_start: {dynamic: true},
    date_end: {dynamic: true},
    start_date: {dynamic: true},
    end_date: {dynamic: true},
    status: {dynamic: true},
    sid: {dynamic: true},
    workflow_id: {dynamic: true},
    current_step: {dynamic: true},
    quantity: {dynamic: true},
    good_piece: {dynamic: true},
    target_material: {dynamic: true, raw: true},
    oa_nr: {dynamic: true},
    sds_nr: {dynamic: true},
    kd_art_nr: {dynamic: true},
    customer: {dynamic: true},
    urgency: {dynamic: true},
    date_created: {dynamic: true},
    e_test: {dynamic: true},
    variation_id: {dynamic: true},
    range_start_start: {dynamic: true},
    range_start_end: {dynamic: true},
    range_end_start: {dynamic: true},
    range_end_end: {dynamic: true},
    lp_type: {dynamic: true},
    hdi_type: {dynamic: true},
    is_reproduction: {dynamic: true},
    has_empb: {dynamic: true},
    has_kanban: {dynamic: true},
    has_plugging: {dynamic: true},
    has_copper_filling: {dynamic: true},
    has_reproduction: {dynamic: true},
    has_blind_hole: {dynamic: true},
    is_type3: {dynamic: true},
    is_type6: {dynamic: true},
  },
  data: {requiresAuth: true}
};

export function baDetailResolveFn(service: BusinessOrderService, transition: Transition) {
  return service.detail(transition.params().id, transition.params().index).toPromise();
}

export function baFetchResolveFn(service: BusinessOrderService, transition: Transition) {
  return service.getFetches(transition.params().id, transition.params().index).toPromise();
}

export const BUSINESS_ORDER_VIEW_STATE: Ng2StateDeclaration = {
  name: 'a.business_order.view',
  url: '/workflow/business/order/{id:[0-9]+}/{index:[0-9]+}',
  views: {'main@a': {component: BusinessOrderViewComponent}},
  resolve: [
    {token: 'data', resolveFn: baDetailResolveFn, deps: [BusinessOrderService, Transition]},
    {token: 'fetches', resolveFn: baFetchResolveFn, deps: [BusinessOrderService, Transition]}
  ],
  data: {requiresAuth: true}
};

export const BUSINESS_ORDER_STATES: Ng2StateDeclaration[] = [
  BUSINESS_ORDER_STATE,
  BUSINESS_ORDER_CREATE_STATE,
  BUSINESS_ORDER_LIST_STATE,
  BUSINESS_ORDER_VIEW_STATE,
];
