import * as React from 'react';
import {DateHelper} from '../../helper/date-helper';

interface Props {
  isoDateTime?: string;
  showDate?: boolean;
  showTime?: boolean;
}

export function EvDate(props: Props): JSX.Element {
  return <>{DateHelper.formatDateTime(DateHelper.parseISO8601(props.isoDateTime), props)}</>;
}
