import {Component, Input} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {GlobalService} from '../../../../core/global.service';
import {ArticleCalculatorService} from '../services/article-calculator.service';
import {ArticleUpdateSharedComponent} from '../helper/article-update-shared.component';
import {ArticleUpdateService} from '../../component-service/article-update.service';
import {StateService} from '@uirouter/angular';
import {ArticleMeasurementLister} from '../../../../react/article/analysis/article-measurement-analysis.model';

@Component({
  selector: 'article-detail',
  templateUrl: './article-detail.component.html'
})
export class ArticleDetailComponent extends ArticleUpdateSharedComponent {
  @Input() measurementLists: ArticleMeasurementLister[];
  constructor(protected fb: UntypedFormBuilder,
              protected articleCalculatorService: ArticleCalculatorService,
              protected globalService: GlobalService,
              protected stateService: StateService,
              protected articleUpdateService: ArticleUpdateService) {
    super();
  }

  measurementListUpdate(list: ArticleMeasurementLister): void {
    this.measurementLists = [
      ...this.measurementLists.filter(ml => ml.questionnaire.id < list.questionnaire.id),
      list,
      ...this.measurementLists.filter(ml => ml.questionnaire.id > list.questionnaire.id),
    ];
  }
}
